import React from 'react';
import { TiTick } from "react-icons/ti";
import {RiMoneyEuroBoxFill} from "react-icons/ri";
import {FaShippingFast} from "react-icons/fa";
import {FaParking} from "react-icons/fa"
import { MdNetworkWifi } from "react-icons/md";
import { FiMinusCircle } from "react-icons/fi";
import "./main.css";
const ShopService = ({services}) => {
 console.log("services",services);
    return (<>
    <div className="shopservice__container">
    <h4>
Dienstleistungen, die wir unseren Kunden anbieten :</h4>
    <div className="service__details">
    <div className="service__container">
            <div className="service__checkbox">
                    {services.free_wifi? <TiTick className="service__icons" /> : <FiMinusCircle color="red" className="service__icons"/>}
            
            </div>
            <div className="service__symbol">
            <MdNetworkWifi className="service__icons service__preicon" />
            </div>
            <div className="service__title">Wlan</div>
    </div>
    <div className="service__container">
            <div className="service__checkbox">
            {services.online_payments? <TiTick className="service__icons" /> : <FiMinusCircle color="red" className="service__icons"/>}
            </div>
            <div className="service__symbol">
            <RiMoneyEuroBoxFill className="service__icons service__preicon"/>
            </div>
            <div className="service__title">Kartenzahlung möglich</div>
    </div>
    <div className="service__container">
            <div className="service__checkbox">
            {services.shipping? <TiTick className="service__icons" /> : <FiMinusCircle color="red" className="service__icons"/>}
            </div>
            <div className="service__symbol">
            <FaShippingFast className="service__icons service__preicon"/>
            </div>
            <div className="service__title">Versand möglich</div>
    </div>
    <div className="service__container">
            <div className="service__checkbox">
            {services.free_parking? <TiTick className="service__icons" /> : <FiMinusCircle color="red" className="service__icons"/>}
            </div>
            <div className="service__symbol">
            <FaParking className="service__icons service__preicon"/>
            </div>
            <div className="service__title">Kundenparkplätze vorhanden</div>
    </div>
    </div>
    </div>
    </> );
}
 
export default ShopService;