import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Loading from '../../components/Loading/Loading';
import Map from '../../components/Map/map';
import ShopService from '../../components/ShopServices/Shop__services';
import {rootUrl} from "../../config";

import "./main.css"
const Shop = ({match}) => {
    useEffect(async() => {
        async function fetchData() {
            const response = await axios.get(`${rootUrl}/shop/${match.params.id}`);
            const shop = response.data;
            setShop(shop);
          }

          fetchData();
    //    const result = await axios.get(`${rootUrl}/shop/${match.params.id}`);
        

        
    }, [])

    const [shop,setShop]  = useState();
const convertNumToTime =(number)=> {
    // Check sign of given number
    const sign = (number >= 0) ? 1 : -1;
    // Set positive value of number of sign negative
    number = number * sign;
    // Separate the int from the decimal part
    const hour = Math.floor(number);
    console.log(hour);
    let decpart = number - hour;
    const min = decpart*100;
    console.log(min,"minute");
    let minute = Math.round(min);
    
    if (minute==0) {
        minute = '0' + minute; 
        }
    const time =  hour + ':' + minute;
    return time;
}
    return (<>{shop?  <>
        <div style={{backgroundImage:`url(/category/${shop.category.replaceAll(/\s/g,'')}.jpg)`}} className="shop_background"></div>
        <div className="shop__info">
                <h2>{shop.name} <span className="shop__category">{shop.category}</span></h2>
        </div>
        <div className="shop__contact">{
            shop.contacts.email? <div className="contact__mail">
            <h2>{shop.contacts.email}</h2>
          <div className="contact__backgroundmail"></div>
        </div>:<div className="contact__mail">
            <h2>Nicht verfügbar</h2>
          <div className="contact__backgroundmail"></div>
        </div>
        }
           {shop.contacts.phone?<div className="contact__mail">
            <h2>{shop.contacts.phone}</h2>
            <div className="contact__backgroundphone"></div>
            </div>:
            <div className="contact__mail">
            <h2>Nicht verfügbar</h2>
            <div className="contact__backgroundphone"></div>
            </div>
           }
            {shop.timing.start||shop.timing.end?<div className="contact__mail">
            <h2>{convertNumToTime(shop.timing.start) } - {convertNumToTime(shop.timing.end)} </h2>
            <div className="contact__backgroundtime"></div>
            </div>:
            <div className="contact__mail">
            <h2>Nicht verfügbar</h2>
            <div className="contact__backgroundtime"></div>
            </div>
            }
            
        </div>
        <ShopService services={shop.facilities}/>
        <div className="shop__about">
            <h3>Shop-Details</h3>
            <div className="shop__description">
                <p>{shop.about}</p>
            </div>
        </div>
        <div className="shop__location">
            <h3>Standort</h3>
            <div className="map__container">
            <Map center={shop.location.coordinates}/>
            </div>
        </div>
        {
            shop.website? <div className="website__link">
            <a  target="_blank" href={shop.website}>zu unserer Website</a>
        </div>: <div className="website__link">
            <a href="#">Nach oben</a>
        </div>
        }
       
        </>:<Loading/>} </>
    );
}
 
export default Shop;
