import React from "react";
import MegaMenu from "../components/MegaMenu/MegaMenu";
import Paginate from "../components/Paginate";
import {Link} from "react-router-dom"
import ShopCard from "../components/ShopCards/ShopCard";
import "./main.css";
import Loading from "../components/Loading/Loading"
import { useSearch } from "../contexts/SearchContextProvider";
import Slider from "../components/slideShow";
const Home = () => {
  const { shops, getter } = useSearch()
  console.log(getter.coordinates.address===undefined, "TEST");
  return (
    <div className="home__container">
      <div className="home__backgroundImage">
        <Slider/>
      </div>
      <span className="home__decor"></span>
     
      <MegaMenu />
   
  {
    getter.isLoading?<Loading/>:<>
    <div className="home__herocontainer">
        <div className="home__cardcontainer">
        {shops.length !== 0 ? shops.map(shop => {
          console.log("SHOPS",shops)
          return <ShopCard key={shop._id} shop={shop}/>
        }): <div className="home__selectlocation">

          <h4>Wähle einen Standort um Unternehmen in deiner Umgebung anzuzeigen.</h4>
          <div className="website__link">
            <a href="#">Nach oben</a>
        </div>
        </div>
        }
          
        </div>
      </div>
      <div
        className="home__line"
        style={{
          width: "85vw",
          height: "1px",
          background: "#AAAAAA",
          marginTop: "5vh",
          marginBottom: "5vh",
        }}
      ></div>
      {/* <button className="home__loadmore">LOAD MORE</button> */}
      {/* <PreFooter /> */}
       
      
    </>
  }
   <Paginate />
      <div
        className="home__line"
        style={{
          width: "85vw",
          height: "1px",
          background: "#AAAAAA",
          marginTop: "5vh",
          marginBottom: "5vh",
        }}
      ></div>

      <div className="contribute_shopcontainer">
        <Link className="contribute__link" to={"/contribute"}>Unternehmen eintragen</Link>
      </div>
    </div>
  );
};

export default Home;
