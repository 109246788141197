export const categories = [
  {
    id: 1,
    category: "Lebensmittel",
    name: "food",
  },
  {
    id: 2,
    category: "Schuhe",
    name: "Shoes",
  },
  {
    id: 3,
    category: "Haustierbedarf",
    name: "pet food",
  },
  {
    id: 4,
    category: "Hygiene Produkte",
    name: "hygiene products",
  },
  {
    id: 5,
    name: "Furniture",
    category: "Möbel",
  },
  {
    id: 6,
    name: "jewellery",
    category: "Schmuck",
  },
  {
    id: 7,
    name: "Toys",
    category: "Spielzeug",
  },
  {
    id: 8,
    name: "dress",
    category: "Bekleidung",
  },
  {
    id: 9,
    name: "beverages",
    category: "Getränke",
  },
  {
    id: 10,
    name: "electronic",
    category: "Elektronik",
  },
  {
    id: 11,
    name: "home accessorie",
    category: "Heimzubehör",
  },

  {
    id: 12,
    name: "leisure items",
    category: "Freizeitartikel",
  },
  {
    id: 13,
    name: "farm products",
    category: "gutes vom bauern",
  },
  {
    id: 14,
    name: "startups",
    category: "Startups",
  },
  {
    id: 15,
    name: "consulting",
    category: "unternehmensberatung",
  },
  {
    id: 16,
    name: "fotografie",
    category: "fotografie",
  },
  {
    id: 17,
    name: "fitness",
    category: "fitness",
  },
  {
    id: 18,
    name: "handwerker",
    category: "handwerker",
  },
  {
    id: 19,
    name: "kunst & kultur",
    category: "kunst & kultur",
  },
  {
    id: 20,
    name: "gesundheit",
    category: "gesundheit",
  },
  {
    id: 21,
    name: "baumärkte",
    category: "baumärkte",
  },
  {
    id: 22,
    name: "alles für´s auto",
    category: "alles für´s auto",
  },
  {
    id: 23,
    name: "gastronomie",
    category: "gastronomie",
  },
  {
    id: 24,
    name: "banken",
    category: "banken",
  },
  {
    id: 26,
    name: "hotels",
    category: "hotels",
  },
  {
    id: 27,
    name: "apotheken",
    category: "apotheken",
  },
  {
    id: 28,
    name: "kinderärzte",
    category: "kinderärzte",
  },
  {
    id: 29,
    name: "frauenärzte",
    category: "frauenärzte",
  },
  {
    id: 30,
    name: "reisebüros",
    category: "reisebüros",
  },
  {
    id: 31,
    name: "tankstellen",
    category: "tankstellen",
  },
  {
    id: 32,
    name: "post-filialen",
    category: "post-filialen",
  },
  {
    id: 33,
    name: "flughäfen",
    category: "flughäfen",
  },
  {
    id: 34,
    name: "buchhandlungen",
    category: "buchhandlungen",
  },
  {
    id: 35,
    name: "büro & schule",
    category: "büro & schule",
  },
  {
    id: 36,
    name: "friseure",
    category: "friseure",
  },
  {
    id: 37,
    name: "rechtsanwälte",
    category: "rechtsanwälte",
  },
  {
    id: 38,
    name: "bestatter",
    category: "bestatter",
  },
  {
    id: 39,
    name: "blumenhändler",
    category: "blumenhändler",
  },
  {
    id: 40,
    name: "tabakwaren",
    category: "tabakwaren",
  },
  {
    id: 0,
    name: "all",
    category: "Alle kategorien",
  },
];
