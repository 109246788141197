import React from 'react';
import ContributeForm from '../../components/Form';

import "./main.css"
const ContributePage = () => {
    return ( <>
    <div className="admin__background">
        <span className="admin__bgfilter"></span>
        <h2>Unternehmen eintragen</h2>
    </div>
    <div className="admin__formcontainer">
       <ContributeForm/>
    </div>
    </> );
}
 
export default ContributePage;