import React from 'react';
import {GoogleMap,useLoadScript,Marker} from "@react-google-maps/api";
import "./main.css"


const libraries= ["places"];
const mapContainerStyle={
     width:"100%",
     height:"50vh"
}

const options ={
    disableDefaultUI: true,
    zoomControl:true
}
const Map = ({center}) => {
  const location={
    lat:center[1],
    lng:center[0]
}
const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey : process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
libraries
    })
if(loadError) return "Error loading maps";
if(!isLoaded) return "Loading Maps";

    return (    <>
    <GoogleMap mapContainerStyle={mapContainerStyle}
    zoom={13} 
    center={location}
    options={options}
      >
    <Marker position={location} />
      </GoogleMap>
    </>  );
}
 
export default Map;