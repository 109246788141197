import React from 'react';
import "./main.css"
const Contact = () => {
    return (<>
    <div className="contact__container">
            <h1>RegioHeld.at </h1>
            <h3>Geschäftsführer: Peter Oberlik</h3>
            <h3>Email: info@regioheld.at</h3>
            <h3>Support: support@regioheld.at</h3>
    </div>
    </>  );
}
 
export default Contact;