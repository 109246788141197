import usePlacesAutocomplete, {
    getGeocode,
    getLatLng,
  } from "use-places-autocomplete";
  import useOnclickOutside from "react-cool-onclickoutside";
  import "./main.css";
  // import Locate from "../LocateButton";
  // import { useSearch } from "../../contexts/SearchContextProvider";
  import { useEffect } from "react";
  
  const SearchLocation = ({ coords, setCoords }) => {
    const {
      ready,
      value,
      suggestions: { status, data },
      setValue,
      clearSuggestions,
    } = usePlacesAutocomplete({
      requestOptions: {
        /* Define search scope here */
      },
      debounce: 300,
    });
  
    // update the value of the box if it is already present in the context
    useEffect(() => {
      if (coords && coords.address) {
        setValue(coords.address, false);
      }
    }, [coords.address]);
  
    const ref = useOnclickOutside(() => {
      // When user clicks outside of the component, we can dismiss
      // the searched suggestions by calling this method
      clearSuggestions();
    });
  
    const handleInput = (e) => {
      // Update the keyword of the input element
      setValue(e.target.value);
    };
  
    const handleSelect = ({ description }) => () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();
  
      // Get latitude and longitude via utility functions
      getGeocode({ address: description })
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          setCoords({
            latitude: lat,
            longitude: lng,
            address: description,
          });
          // console.log(con)
          console.log("📍 Coordinates: ", { lat, lng });
        })
        .catch((error) => {
          console.log("😱 Error: ", error);
        });
    };
  
    const renderSuggestions = () =>
      data.map((suggestion) => {
        const {
          place_id,
          structured_formatting: { main_text, secondary_text },
        } = suggestion;
  
        return (
          <div style={{cursor:"pointer"}} key={place_id} onClick={handleSelect(suggestion)}>
            <strong>{main_text}</strong> <small>{secondary_text}</small>
          </div>
        );
      });
  
    return (
      <div className="locationbar__containerform" ref={ref}>
        {/* <Locate coords={coords} setCoords={setCoords} /> */}
        <div className="wrapperform">
          <input
            style={{ display: "block" }}
            value={value}
            onChange={handleInput}
            disabled={!ready}
            placeholder=" Suchen Sie nach dem Ort"
            className="search__locationbarform"
          />
          {/* We can use the "status" to decide whether we should display the dropdown or not */}
          {status === "OK" && (
            <div className="suggestion__containerform">{renderSuggestions()}</div>
          )}
        </div>
      </div>
    );
  };
  export default SearchLocation;
  