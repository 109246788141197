import React from 'react';
import "./main.css";
const Team = () => {
    return (<div className="team__container">
    <h1>Das Team</h1>
    <div className="team__cardscontainer">
    <div className="team__card">
        <div className="team__image team__peter"></div>
        <div className="team__description">
            <h6>Peter Oberlik</h6>
            <h4>Founder & CEO</h4>
        </div>
    </div>
     <div className="team__card">
        <div className="team__carina team__image "></div>
        <div className="team__description">
            <h6>Carina Sehling </h6>
            <h4>Head of Customer-Service</h4>
        </div>
    </div>
    </div>
   
    </div>);
}
 
export default Team;