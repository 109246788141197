import React from 'react'
import { Link } from 'react-router-dom';
import {ImLocation} from "react-icons/im"
import "./main.css"
const ShopCard = ({shop}) => {
    return (
        
    <div className="shopcard__container">
        <div style={{backgroundImage:`url(/category/${shop.category.replaceAll(/\s/g,'')}.jpg)`}}  className="shopcard__background">
        </div>
        <h2>{shop.name}</h2>
        <p className="shopcard__address" > <ImLocation size="1.5vw" color="#FF7379"/> {shop.address}</p>
        <Link to={`/shop/${shop._id}`} className="shop__link">
Shop besuchen</Link>
    </div>  );
}
 
export default ShopCard;
// hygieneProdukte