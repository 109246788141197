import React from 'react';
import "./main.css";
import {Link} from "react-router-dom";
const About = () => {
    return (   <>
    <div className="about__container">
        <h4>ÜBER UNS</h4>
        <h5>Heldenhafte Vorstellung </h5>
        <h5>Willkommen bei RegioHeld.at</h5>
        <p>Unsere Plattform wurde entwickelt um die richtigen Helden des Alltags in den Vordergrund zu rücken. Nämlich die Unternehmer.

Es gibt tausende Unternehmer die durch die Krise einen 100%igen Umsatzverlust hinnehmen mussten und kämpfen wort wörtlich um´s Überleben.
Diese wollen wir nun bestmöglich unterstützen und ihnen die Existenzangst so gut es geht nehmen.

RegioHeld.at kämpft nicht gegen Amazon und Co, sondern möchte REGIONALITÄT wieder in den Mittelpunkt stellen und auch die kleinen Unternehmen vor den Vorhang holen.
Auch die Unternehmen der D-A-CH Region haben tolle Produkte und tolle Angebote zu bieten und stehen den großen um nichts nach.

Wir sind der Held in deiner Region und zeigen dir tolle Produkte und Unternehmen in deiner Nähe.


Mit RegioHeld kannst du Unternehmen suchen und deren Produkte dann gleich in unserem Marktplatz kaufen.

Wir listen Unternehmen aller Branchen auf der Plattform.</p>
<h5>Die Eintragung für alle Unternehmen ist absolut kostenlos:
<div className="register_shopcontainer">
             <Link className="register__link" to={"/contribute"}>Unternehmen eintragen</Link>
</div>
</h5>


{/* <div className="about__known">
RegioHeld.at ist bekannt aus:
</div> */}
    </div>
    </>  );
}
 
export default About;  