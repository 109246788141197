import React from 'react';
import { Link } from 'react-router-dom';
import "./main.css"
const NotFound = () => {
    return ( <>
    <div className="not-found">
        <h1>Diese Seite kann nicht gefunden werden</h1>
        <div className="register_shopcontainer">
             <Link className="register__link" to={"/home"}>Zuhause</Link>
</div>
    </div>
    </> );
}
 
export default NotFound;