import SwiperCore, { Autoplay, Navigation, Pagination, Scrollbar, A11y } from 'swiper';

import { Swiper, SwiperSlide } from 'swiper/react';
import "./main.css"
// Import Swiper styles
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
// install Swiper components
SwiperCore.use([Navigation, Pagination, Scrollbar, A11y]);

export default () => {
  SwiperCore.use([Autoplay])
  return (
    <Swiper
      spaceBetween={10}
      slidesPerView={1}
      autoplay={{ delay: 5000 }}
      pagination={{  el: '.swiper-pagination',
      dynamicBullets: true, }}
      loop={true}
      scrollbar={{ draggable: true }}
      style={{height:"100%" }}
    >
      <SwiperSlide className="slide__bg1"></SwiperSlide>
      <SwiperSlide className="slide__bg2"></SwiperSlide>
      <SwiperSlide className="slide__bg3"></SwiperSlide>
      <SwiperSlide className="slide__bg4"></SwiperSlide>
    </Swiper>
  );
};