import React from 'react';
import "./main.css"
const ShippingPage = () => {
    return ( <div className="shipping__container">
        <h1>Versand</h1>
        <h3>Der Versand wird vom jeweiligen Startup / Unternehmen vorgenommen.
Daher kann der Preis und die Dauer variieren.</h3>
<h3>RegioHeld.at übernimmt keine Haftung für den Versand der Produkte & Artikel auf der Plattform.</h3>
    </div> );
}
 
export default ShippingPage;