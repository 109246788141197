import React from 'react';
import "./main.css"
import { Link } from 'react-router-dom';
const SearchShop = ({shop}) => {
    return ( <div className="shop__container">
        <div style={{backgroundImage:`url(/category/${shop.category.replaceAll(/\s/g,'')}.jpg)`}} className="shop__image"></div>
        <div className="shop__discription">
            <h1>{shop.name}</h1>
            <p>{shop.address}</p>
            <h4>{shop.about}</h4>
            <Link to={`/shop/${shop._id}`} className="shop__visit">Shop besuchen</Link>
        </div>
    </div> );
}
 
export default SearchShop;