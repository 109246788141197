import React from 'react';
import {BiCurrentLocation}  from "react-icons/bi";
import "./main.css"
import {setCurrentLocation} from '../../utils/setCurrentLocation'
import { useSearch } from "../../contexts/SearchContextProvider";

const Locate = () => {
    const {setter} = useSearch()
    return ( <button style={{display:"block"}} className="locate__btn" onClick={() => setCurrentLocation(setter)} >
   <BiCurrentLocation className="current__locationicon" size="2vw"/>
    </button> );
}
 
export default Locate;