import React from 'react';
import {FaFacebookF,FaInstagram} from "react-icons/fa";
import {AiFillMail} from "react-icons/ai";
import {Link} from 'react-router-dom';
import "./main.css"
const Footer = () => {
    return ( <div className="footer__container">
<div className="footer__sociallinks">
    <h3>Folgt uns auf Social Media!</h3>
    <div className="icon__container">
        <a target="_blank" href="https://www.facebook.com/RegioHeld.at/"><FaFacebookF size="1.8vw"/></a>
        <a target="_blank"  href="mailto:info@regioheld.at?subject = Feedback&body = Message">< AiFillMail size="1.8vw"/></a>
        <a target="_blank"  href="https://www.instagram.com/regioheld.at"><FaInstagram size="1.8vw"/></a>
    </div>
</div>
<div className="footer__mainbody">
    <div className="main__bodyabout">
    <h4 className="about__headerfooter">Über RegioHeld</h4>
    <Link to={"/about"} className="about__para" >Über uns</Link>
    <Link to={"/team"} className="about__para" >Das Team</Link>
    <Link to={"/kontakt"} className="about__para" >Kontakt</Link>
    </div>
    <div className="main__bodyabout">
    <h4 className="about__headerfooter"> Unser Versprechen</h4>
    <p href="#" className="about__para" >✔️ Unterstützer für regionale Startups</p>
    <p href="#" className="about__para" >✔️ Wir leben Nachhaltigkeit </p>
    <p href="#" className="about__para" >✔️ Wir sind 24/7 für dich da </p>
    </div>
    <div className="main__bodyabout">
    <h4 className="about__headerfooter"> Rechtliches</h4>
    <Link to={"/imprint"} className="about__para" >Impressum</Link>
    <Link to={"/Datenschutz"} className="about__para" >Datenschutz</Link>
    <Link to={"/versand"} className="about__para" >Versand </Link>
    </div>
    <div className="main__bodyabout">
    <h4 style={{textAlign:"center"}} className="about__headerfooter">Unsere Partner</h4>
    <a target="_blank" href="https://blackmarbles.tech" className="about__img">
    </a>
    <a target="_blank" href="https://www.kematen-krems.ooe.gv.at/" className="about__imgK">
    </a>
    </div>
    </div> 
<div className="footer__copyright">
    <h3>Copyright ©️ 2021 RegioHeld. All Rights Reserved</h3>
</div>  
    </div> );
}
 
export default Footer;