import React, { useState } from 'react';
import Logo from "../../assets/logo.png";

import {MdSearch} from "react-icons/md";
import {useLoadScript} from "@react-google-maps/api";
import "./main.css"
import { Link, useHistory } from "react-router-dom";
import {useSearch} from '../../contexts/SearchContextProvider'

import Dropdown from '../DropDown';
const libraries= ["places"];


const NavBar = () => {
   
    const [search,setSearch] = useState("");
    const {isLoaded, loadError} = useLoadScript({
        googleMapsApiKey : process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries
    })
    const {getter, setter} = useSearch()
    const history = useHistory();

  
    if(loadError) return "Error loading maps";
    if(!isLoaded) return "Loading Maps";
    const handleSubmit = (e) =>{
        e.preventDefault();
        history.push({
            pathname: "/shops/search",
            search: `search=${search}`,
        });
    };
  
    return ( <div className="navbar__container">
        <div className="navbar__left">
        <div className="logo__container">
            {/*  */}
            <img src={Logo} alt="RegioHeld_Logo">

            </img>
           
        <Link className="logo__link" to={"/?pageNo=1"}> <h4>REGIO<span style={{color:"#FDBA16"}}>HELD</span></h4></Link>   
        
        </div>
        <div className="searchbar__container">
            <form className="navbar__form" onSubmit={handleSubmit}>
                <input  type="text" value={search} onChange={(e)=> setSearch(e.target.value) } placeholder="Suche" className="shop__searchbar" />
                <MdSearch size="2.5vw"/>
            </form>
        </div>
        </div>
         <div className="navright">
         <Link className="marketplace__btn" to={"/contribute"}>Unternehmen eintragen</Link>
         <a className="marketplace__btn" target="_blank" href={process.env.REACT_APP_MARKET_REGIO}>zum Marktplatz</a>
      
        {/* <SearchLocation/> */}
      
      <Dropdown  />
      
        </div>
      
    </div> );
}
 
export default NavBar;