import React, { useState } from 'react';
import useOnclickOutside from "react-cool-onclickoutside";
import {GoLocation}from "react-icons/go";
import SearchLocation from '../Search';
import "./main.css";   


const Dropdown = () => {

    const [openDrop, setOpenDrop] = useState(true);
const navdrop = useOnclickOutside(() => {
    setOpenDrop(false);
  });
  const handleDropBtn = () => {
    setOpenDrop(!openDrop);
  };
    return ( <> 
    <div   className="navbar__menu">
    <GoLocation  color="#FF7379"  onClick={handleDropBtn} size="2.5vw" style={{cursor:"pointer"}} />
</div>
     {openDrop && 
     < SearchLocation />
    
    } 
     
    </>);
       
}
 
export default Dropdown;

{/* <>
    
    <div className="drop__down" ref={navdrop}>
    <div className="navbar__linkscontainer">
  <a className="navlink__btn"  href="#">Unternehmen eintragen</a>
  <a className="navlink__btn"  href="#">Kontakt</a>
  <a className="marketplace__btn" href="#">zum Marktplatz</a>
    </div>
      </div></> */}