import React, { useState } from 'react';
import useOnclickOutside from "react-cool-onclickoutside";
import { useSearch } from '../../contexts/SearchContextProvider';
import "./main.css"
const MegaMenu = () => {

   const {getter,setter} = useSearch();
    const [openMenu, setOpenMenu] = useState(false);
    const ref = useOnclickOutside(() => {
    setOpenMenu(false);
  });
    const handleHeading = (e)=>{
        setter.category(e.target.innerText.toLowerCase());
        // console.log(e.target.getAttribute('name'));
        setOpenMenu(false);
    }
    const handleClickBtn = () => {
        setOpenMenu(!openMenu);
      };
    return ( <>
     {/* <h1 className="home__category">{capitalize(getter.category) || "Alle Kategorien"}</h1> */}
     {getter.category==="alle kategorien"||getter.category===undefined?<><h1 className="home__category">Aus der Region. </h1>
     <h1 style={{marginBottom:"5vh"}} className="home__category">
Für die Region. </h1></>:<h1 className="home__category">{getter.category}</h1>}
     

    <div className="menu__container">
   <div className="menu__categories">
       <button name="All" onClick={handleHeading} >Alle Kategorien</button>
       <button name="Food" onClick={handleHeading} >Lebensmittel</button>
       <button name="Shoes" onClick={handleHeading}>Schuhe</button>
       <button name="Pet Food" onClick={handleHeading} >Haustierbedarf</button>
       <button onClick={handleClickBtn} >Mehr anzeigen</button>
   </div>
   {openMenu && <div className="menu__allcats" ref={ref}>
       <div className="row">
       <button name="Hygiene-Products" onClick={handleHeading} >Hygiene Produkte</button>
       <button name="Furniture" onClick={handleHeading}  >Möbel</button>
       <button name="Jewellery" onClick={handleHeading} >Schmuck</button>
       <button name="Toys" onClick={handleHeading} >Spielzeug</button>
       <button name="Farm Products" onClick={handleHeading} >Gutes vom Bauern</button>
       <button name="Startups" onClick={handleHeading}>Startups</button>
       <button name="fotografie" onClick={handleHeading}  >fotografie</button>
       <button name="fitness" onClick={handleHeading} >fitness</button>
       <button name="handwerker" onClick={handleHeading} >handwerker</button>
       <button name="kunst & kultur" onClick={handleHeading} >kunst & kultur</button>
       <button name="gesundheit" onClick={handleHeading}>gesundheit</button>
       <button name="hotels" onClick={handleHeading}>hotels</button>
       <button name="tankstellen" onClick={handleHeading}>tankstellen</button>
       

       </div>
       
<div className="row">
<button  onClick={handleHeading} name="Dress">Bekleidung</button>
       <button name="Beverages"  onClick={handleHeading}>Getränke</button>
       <button name="Electronic" onClick={handleHeading}>Elektronik</button>
       <button name="Home accessories" onClick={handleHeading}>Heimzubehör</button>
       <button name="Leisure items" onClick={handleHeading}>Freizeitartikel</button>
       <button name="Consulting" onClick={handleHeading}>unternehmensberatung</button>
       <button name="baumärkte" onClick={handleHeading}>baumärkte</button>
       <button name="alles für´s auto" onClick={handleHeading}>alles für´s auto</button>
       <button name="gastronomie" onClick={handleHeading}>gastronomie</button>
       <button name="banken" onClick={handleHeading}>banken</button>
       <button name="zahnärzte" onClick={handleHeading}>zahnärzte</button>
       <button name="post-filialen" onClick={handleHeading}>post-filialen</button>
      

</div>
<div className="row">
<button  onClick={handleHeading} name="Dress">Bekleidung</button>
<button name="flughäfen" onClick={handleHeading}>flughäfen</button>
       <button name="buchhandlungen" onClick={handleHeading}>buchhandlungen</button>
       <button name="büro & schule" onClick={handleHeading}>büro & schule</button>
       <button name="friseure" onClick={handleHeading}>friseure</button>
       <button name="rechtsanwälte" onClick={handleHeading}>rechtsanwälte</button>
       <button name="bestatter" onClick={handleHeading}>bestatter</button>
       <button name="blumenhändler" onClick={handleHeading}>blumenhändler</button>
       <button name="apotheken" onClick={handleHeading}>apotheken</button>
       <button name="kinderärzte" onClick={handleHeading}>kinderärzte</button>
       <button name="frauenärzte" onClick={handleHeading}>frauenärzte</button>
       <button name="reisebüros" onClick={handleHeading}>reisebüros</button>

</div>
      
 
        </div>}
    </div>
    <div
        className="home__line"
        style={{
          width: "85vw",
          height: "1px",
          background: "#AAAAAA",
          marginTop: "5vh",
          marginBottom: "5vh",
        }}
      ></div>
   
    </>
     );
}
 
export default MegaMenu;