import React from 'react';
import "./main.css"
const DataProtection = () => {
    return ( <div className="DP__container">
         <h1>Datenschutzerklärung</h1>
       <h2>Inhaltsverzeichnis</h2>
       <h3>Datenschutz</h3>
       <h3>Automatische Datenspeicherung</h3>
       <h3>Cookies</h3>
       <h3>Speicherung persönlicher Daten</h3>
       <h3>Rechte laut Datenschutzgrundverordnung</h3>
       <h3>Auswertung des Besucherverhaltens</h3>
       <h3>TLS-Verschlüsselung mit https</h3>
       <h3>Google Maps Datenschutzerklärung</h3>
       <h3>Google Fonts Datenschutzerklärung</h3>
       <h3>Google Fonts Lokal Datenschutzerklärung</h3>
       <h3>Google Analytics Datenschutzerklärung</h3>
       <h3>Facebook-Pixel Datenschutzerklärung</h3>
       <h3>Newsletter Datenschutzerklärung</h3>
       <h3>Google AdSense Datenschutzerklärung</h3>
       <h3>Eingebettete Social Media Elemente Datenschutzerklärung</h3>
       <h3>Facebook Datenschutzerklärung</h3>
       <h3>Instagram Datenschutzerklärung</h3>
       <h3>Benutzerdefinierte Google Suche Datenschutzerklärung</h3>

<h2>Datenschutz</h2>
        <h4>Wir haben diese Datenschutzerklärung (Fassung 01.01.1970-121628621) verfasst, um Ihnen gemäß der Vorgaben der Datenschutz-Grundverordnung (EU) 2016/679 zu erklären, welche Informationen wir sammeln, wie wir Daten verwenden und welche Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben.

Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr technisch klingen, wir haben uns bei der Erstellung jedoch bemüht die wichtigsten Dinge so einfach und klar wie möglich zu beschreiben.</h4>
      <h2>Automatische Datenspeicherung</h2>
      <h4>Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen automatisch erstellt und gespeichert, so auch auf dieser Webseite.

Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser Webserver (Computer auf dem diese Webseite gespeichert ist) automatisch Daten wie</h4>
      <h3>die Adresse (URL) der aufgerufenen Webseite</h3>
      <h3>Browser und Browserversion</h3>
      <h3>das verwendete Betriebssystem</h3>
      <h3>die Adresse (URL) der zuvor besuchten Seite (Referrer URL)</h3>
      <h3>den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird</h3>
      <h3>Datum und Uhrzeit</h3>
      <h4>in Dateien (Webserver-Logfiles).

In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.</h4>
      <h2>Cookies</h2>
      <h4>Unsere Webseite verwendet HTTP-Cookies, um nutzerspezifische Daten zu speichern.
Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende Datenschutzerklärung besser verstehen.</h4>
       <h2>Was genau sind Cookies?</h2> 
       <h4>Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind beispielsweise Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Webseiten speichern kleine Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.

Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle Webseiten verwenden Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies für andere Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, die von unserer Webseite auf Ihrem Computer gespeichert werden. Diese Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem “Hirn” Ihres Browsers, untergebracht. Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition eines Cookies müssen zusätzlich ein oder mehrere Attribute angegeben werden.

Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die „userbezogenen“ Informationen an unsere Seite zurück. Dank der Cookies weiß unsere Webseite, wer Sie sind und bietet Ihnen die Einstellung, die Sie gewohnt sind. In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie beispielsweise Firefox sind alle Cookies in einer einzigen Datei gespeichert.

Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Webseiten (z.B. Google Analytics) erstellt. Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme und enthalten keine Viren, Trojaner oder andere „Schädlinge“. Cookies können auch nicht auf Informationen Ihres PCs zugreifen.

So können zum Beispiel Cookie-Daten aussehen:

Name: _ga
Wert: GA1.2.1326744211.152121628621-9
Verwendungszweck: Unterscheidung der Webseitenbesucher
Ablaufdatum: nach 2 Jahren

Diese Mindestgrößen sollte ein Browser unterstützen können:</h4> 
<h3>Mindestens 4096 Bytes pro Cookie</h3>  
<h3>Mindestens 50 Cookies pro Domain</h3>      
<h3>Mindestens 3000 Cookies insgesamt</h3>
  <h2>Welche Arten von Cookies gibt es?</h2>    
  <h4>Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und wird in den folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die verschiedenen Arten von HTTP-Cookies eingehen.

Man kann 4 Arten von Cookies unterscheiden:

Unerlässliche Cookies
Diese Cookies sind nötig, um grundlegende Funktionen der Webseite sicherzustellen. Zum Beispiel braucht es diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft und später erst zur Kasse geht. Durch diese Cookies wird der Warenkorb nicht gelöscht, selbst wenn der User sein Browserfenster schließt.

Zweckmäßige Cookies
Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige Fehlermeldungen bekommt. Zudem werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Webseite bei verschiedenen Browsern gemessen.

Zielorientierte Cookies
Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene Standorte, Schriftgrößen oder Formulardaten gespeichert.

Werbe-Cookies
Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell angepasste Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.

Üblicherweise werden Sie beim erstmaligen Besuch einer Webseite gefragt, welche dieser Cookiearten Sie zulassen möchten. Und natürlich wird diese Entscheidung auch in einem Cookie gespeichert.</h4>  
      <h2>Wie kann ich Cookies löschen?</h2>
      <h4>Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder welcher Webseite die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, zu deaktivieren oder nur teilweise zuzulassen. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle anderen Cookies zulassen.

Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie Cookie-Einstellungen ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen finden:

Chrome: Cookies in Chrome löschen, aktivieren und verwalten

Safari: Verwalten von Cookies und Websitedaten mit Safari

Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben

Internet Explorer: Löschen und Verwalten von Cookies

Microsoft Edge: Löschen und Verwalten von Cookies

Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie das Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser verschieden. Am besten Sie suchen die Anleitung in Google mit dem Suchbegriff “Cookies löschen Chrome” oder “Cookies deaktivieren Chrome” im Falle eines Chrome Browsers.</h4>
      <h2>Wie sieht es mit meinem Datenschutz aus?</h2>

      <h4>Seit 2009 gibt es die sogenannten „Cookie-Richtlinien“. Darin ist festgehalten, dass das Speichern von Cookies eine Einwilligung von Ihnen verlangt. Innerhalb der EU-Länder gibt es allerdings noch sehr unterschiedliche Reaktionen auf diese Richtlinien. In Österreich erfolgte aber die Umsetzung dieser Richtlinie in § 96 Abs. 3 des Telekommunikationsgesetzes (TKG).

Wenn Sie mehr über Cookies wissen möchten und technische Dokumentationen nicht scheuen, empfehlen wir https://tools.ietf.org/html/rfc6265, dem Request for Comments der Internet Engineering Task Force (IETF) namens “HTTP State Management Mechanism”.</h4>
      <h2>Speicherung persönlicher Daten</h2>


      <h4>Persönliche Daten, die Sie uns auf dieser Website elektronisch übermitteln, wie zum Beispiel Name, E-Mail-Adresse, Adresse oder andere persönlichen Angaben im Rahmen der Übermittlung eines Formulars oder Kommentaren im Blog, werden von uns gemeinsam mit dem Zeitpunkt und der IP-Adresse nur zum jeweils angegebenen Zweck verwendet, sicher verwahrt und nicht an Dritte weitergegeben.

Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit jenen Besuchern, die Kontakt ausdrücklich wünschen und für die Abwicklung der auf dieser Webseite angebotenen Dienstleistungen und Produkte. Wir geben Ihre persönlichen Daten ohne Zustimmung nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.

Wenn Sie uns persönliche Daten per E-Mail schicken – somit abseits dieser Webseite – können wir keine sichere Übertragung und den Schutz Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten niemals unverschlüsselt per E-Mail zu übermitteln.</h4>
       <h2>Rechte laut Datenschutzgrundverordnung</h2>
       <h4>Ihnen stehen laut den Bestimmungen der DSGVO und des österreichischen Datenschutzgesetzes (DSG) grundsätzlich die folgende Rechte zu:

Recht auf Berichtigung (Artikel 16 DSGVO)
Recht auf Löschung („Recht auf Vergessenwerden“) (Artikel 17 DSGVO)
Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)
Recht auf Benachrichtigung – Mitteilungspflicht im Zusammenhang mit der Berichtigung oder Löschung personenbezogener Daten oder der Einschränkung der Verarbeitung (Artikel 19 DSGVO)
Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)
Widerspruchsrecht (Artikel 21 DSGVO)
Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung — einschließlich Profiling — beruhenden Entscheidung unterworfen zu werden (Artikel 22 DSGVO)
Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich bei der Aufsichtsbehörde beschweren, welche in Österreich die Datenschutzbehörde ist, deren Webseite Sie unter https://www.dsb.gv.at/ finden.</h4>
       <h2>Auswertung des Besucherverhaltens</h2>
       <h4>In der folgenden Datenschutzerklärung informieren wir Sie darüber, ob und wie wir Daten Ihres Besuchs dieser Website auswerten. Die Auswertung der gesammelten Daten erfolgt in der Regel anonym und wir können von Ihrem Verhalten auf dieser Website nicht auf Ihre Person schließen.

Mehr über Möglichkeiten dieser Auswertung der Besuchsdaten zu widersprechen erfahren Sie in der folgenden Datenschutzerklärung.</h4>
        <h2>TLS-Verschlüsselung mit https</h2>
        <h4>Wir verwenden https um Daten abhörsicher im Internet zu übertragen (Datenschutz durch Technikgestaltung Artikel 25 Absatz 1 DSGVO). Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur sicheren Datenübertragung im Internet können wir den Schutz vertraulicher Daten sicherstellen. Sie erkennen die Benutzung dieser Absicherung der Datenübertragung am kleinen Schlosssymbol links oben im Browser und der Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.</h4>
        <h2>Google Maps Datenschutzerklärung</h2>
        <h4>Wir benützen auf unserer Website Google Maps der Firma Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich. Mit Google Maps können wir Ihnen Standorte besser zeigen und damit unser Service an Ihre Bedürfnisse anpassen. Durch die Verwendung von Google Maps werden Daten an Google übertragen und auf den Google-Servern gespeichert. Hier wollen wir nun genauer darauf eingehen, was Google Maps ist, warum wir diesen Google-Dienst in Anspruch nehmen, welche Daten gespeichert werden und wie Sie dies unterbinden können.</h4>
        <h4>Was ist Google Maps?</h4>
        <h4>Google Maps ist ein Internet-Kartendienst der Firma Google. Mit Google Maps können Sie online über einen PC, ein Tablet oder eine App genaue Standorte von Städten, Sehenswürdigkeiten, Unterkünften oder Unternehmen suchen. Wenn Unternehmen auf Google My Business vertreten sind, werden neben dem Standort noch weitere Informationen über die Firma angezeigt. Um die Anfahrtsmöglichkeit anzuzeigen, können Kartenausschnitte eines Standorts mittels HTML-Code in eine Website eingebunden werden. Google Maps zeigt die Erdoberfläche als Straßenkarte oder als Luft- bzw. Satellitenbild. Dank der Street View Bilder und den hochwertigen Satellitenbildern sind sehr genaue Darstellungen möglich.</h4>
        <h4>Warum verwenden wir Google Maps auf unserer Webseite?</h4>
      <h4>All unsere Bemühungen auf dieser Seite verfolgen das Ziel, Ihnen eine nützliche und sinnvolle Zeit auf unserer Webseite zu bieten. Durch die Einbindung von Google Maps können wir Ihnen die wichtigsten Informationen zu diversen Standorten liefern. Sie sehen auf einen Blick wo wir unseren Firmensitz haben. Die Wegbeschreibung zeigt Ihnen immer den besten bzw. schnellsten Weg zu uns. Sie können den Anfahrtsweg für Routen mit dem Auto, mit öffentlichen Verkehrsmitteln, zu Fuß oder mit dem Fahrrad abrufen. Für uns ist die Bereitstellung von Google Maps Teil unseres Kundenservice.</h4>
      <h4>Welche Daten werden von Google Maps gespeichert?</h4>
      <h4>Damit Google Maps ihren Dienst vollständig anbieten kann, muss das Unternehmen Daten von Ihnen aufnehmen und speichern. Dazu zählen unter anderem die eingegebenen Suchbegriffe, Ihre IP-Adresse und auch die Breiten- bzw. Längenkoordinaten. Benutzen Sie die Routenplaner-Funktion wird auch die eingegebene Startadresse gespeichert. Diese Datenspeicherung passiert allerdings auf den Webseiten von Google Maps. Wir können Sie darüber nur informieren, aber keinen Einfluss nehmen. Da wir Google Maps in unsere Webseite eingebunden haben, setzt Google mindestens ein Cookie (Name: NID) in Ihrem Browser. Dieses Cookie speichert Daten über Ihr Userverhalten. Google nutzt diese Daten in erster Linie, um eigene Dienste zu optimieren und individuelle, personalisierte Werbung für Sie bereitzustellen.

Folgendes Cookie wird aufgrund der Einbindung von Google Maps in Ihrem Browser gesetzt:

Name: NID
Wert: 188=h26c1Ktha7fCQTx8rXgLyATyITJ121628621-5
Verwendungszweck: NID wird von Google verwendet, um Werbeanzeigen an Ihre Google-Suche anzupassen. Mit Hilfe des Cookies „erinnert“ sich Google an Ihre am häufigsten eingegebenen Suchanfragen oder Ihre frühere Interaktion mit Anzeigen. So bekommen Sie immer maßgeschneiderte Werbeanzeigen. Das Cookie enthält eine einzigartige ID, die Google benutzt, um Ihre persönlichen Einstellungen für Werbezwecke zu sammeln.
Ablaufdatum: nach 6 Monaten

Anmerkung: Wir können bei den Angaben der gespeicherten Daten keine Vollständigkeit gewährleisten. Speziell bei der Verwendung von Cookies sind Veränderungen nie auszuschließen. Um das Cookie NID zu identifizieren, wurde eine eigene Testseite angelegt, wo ausschließlich Google Maps eingebunden war.</h4>

<h2>Wie lange und wo werden die Daten gespeichert?</h2>
<h4>Die Google-Server stehen in Rechenzentren auf der ganzen Welt. Die meisten Server befinden sich allerdings in Amerika. Aus diesem Grund werden Ihre Daten auch vermehrt in den USA gespeichert. Hier können Sie genau nachlesen wo sich die Google-Rechenzentren befinden: https://www.google.com/about/datacenters/inside/locations/?hl=de

Die Daten verteilt Google auf verschiedenen Datenträgern. Dadurch sind die Daten schneller abrufbar und werden vor etwaigen Manipulationsversuchen besser geschützt. Jedes Rechenzentrum hat auch spezielle Notfallprogramme. Wenn es zum Beispiel Probleme bei der Google-Hardware gibt oder eine Naturkatastrophe die Server lahm legt, bleiben die Daten ziemlich sicher trotzdem geschützt.

Manche Daten speichert Google für einen festgelegten Zeitraum. Bei anderen Daten bietet Google lediglich die Möglichkeit, diese manuell zu löschen. Weiters anonymisiert das Unternehmen auch Informationen (wie zum Beispiel Werbedaten) in Serverprotokollen, indem es einen Teil der IP-Adresse und Cookie-Informationen nach 9 bzw.18 Monaten löscht.</h4>
        
        <h2>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h2>
        <h4>Mit der 2019 eingeführten automatischen Löschfunktion von Standort- und Aktivitätsdaten werden Informationen zur Standortbestimmung und Web-/App-Aktivität – abhängig von Ihrer Entscheidung – entweder 3 oder 18 Monate gespeichert und dann gelöscht. Zudem kann man diese Daten über das Google-Konto auch jederzeit manuell aus dem Verlauf löschen. Wenn Sie Ihre Standorterfassung vollständig verhindern wollen, müssen Sie im Google-Konto die Rubrik „Web- und App-Aktivität“ pausieren. Klicken Sie „Daten und Personalisierung“ und dann auf die Option „Aktivitätseinstellung“. Hier können Sie die Aktivitäten ein- oder ausschalten.

In Ihrem Browser können Sie weiters auch einzelne Cookies deaktivieren, löschen oder verwalten. Je nach dem welchen Browser Sie verwenden, funktioniert dies immer etwas anders. Die folgenden Anleitungen zeigen, wie Sie Cookies in Ihrem Browser verwalten:

Chrome: Cookies in Chrome löschen, aktivieren und verwalten

Safari: Verwalten von Cookies und Websitedaten mit Safari

Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben

Internet Explorer: Löschen und Verwalten von Cookies

Microsoft Edge: Löschen und Verwalten von Cookies

Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie es erlauben oder nicht.

Google ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework, wodurch der korrekte und sichere Datentransfer persönlicher Daten geregelt wird. Mehr Informationen dazu finden Sie auf https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI. Wenn Sie mehr über die Datenverarbeitung von Google erfahren wollen, empfehlen wir Ihnen die hauseigene Datenschutzerklärung des Unternehmens unter https://policies.google.com/privacy?hl=de.</h4>

<h2>Google Fonts Datenschutzerklärung</h2>
<h4>Auf unserer Website verwenden wir Google Fonts. Das sind die “Google-Schriften” der Firma Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich.

Für die Verwendung von Google-Schriftarten müssen Sie sich nicht anmelden bzw. ein Passwort hinterlegen. Weiters werden auch keine Cookies in Ihrem Browser gespeichert. Die Dateien (CSS, Schriftarten/Fonts) werden über die Google-Domains fonts.googleapis.com und fonts.gstatic.com angefordert. Laut Google sind die Anfragen nach CSS und Schriften vollkommen getrennt von allen anderen Google-Diensten. Wenn Sie ein Google-Konto haben, brauchen Sie keine Sorge haben, dass Ihre Google-Kontodaten, während der Verwendung von Google Fonts, an Google übermittelt werden. Google erfasst die Nutzung von CSS (Cascading Style Sheets) und der verwendeten Schriftarten und speichert diese Daten sicher. Wie die Datenspeicherung genau aussieht, werden wir uns noch im Detail ansehen.</h4>
<h4>Was sind Google Fonts?</h4>
<h4>Google Fonts (früher Google Web Fonts) ist ein Verzeichnis mit über 800 Schriftarten, die Google Ihren Nutzern kostenlos zu Verfügung stellen.

Viele dieser Schriftarten sind unter der SIL Open Font License veröffentlicht, während andere unter der Apache-Lizenz veröffentlicht wurden. Beides sind freie Software-Lizenzen.

Warum verwenden wir Google Fonts auf unserer Webseite?
Mit Google Fonts können wir auf der eigenen Webseite Schriften nutzen, und müssen sie nicht auf unserem eigenen Server hochladen. Google Fonts ist ein wichtiger Baustein, um die Qualität unserer Webseite hoch zu halten. Alle Google-Schriften sind automatisch für das Web optimiert und dies spart Datenvolumen und ist speziell für die Verwendung bei mobilen Endgeräten ein großer Vorteil. Wenn Sie unsere Seite besuchen, sorgt die niedrige Dateigröße für eine schnelle Ladezeit. Des Weiteren sind Google Fonts sichere Web Fonts. Unterschiedliche Bildsynthese-Systeme (Rendering) in verschiedenen Browsern, Betriebssystemen und mobilen Endgeräten können zu Fehlern führen. Solche Fehler können teilweise Texte bzw. ganze Webseiten optisch verzerren. Dank des schnellen Content Delivery Network (CDN) gibt es mit Google Fonts keine plattformübergreifenden Probleme. Google Fonts unterstützt alle gängigen Browser (Google Chrome, Mozilla Firefox, Apple Safari, Opera) und funktioniert zuverlässig auf den meisten modernen mobilen Betriebssystemen, einschließlich Android 2.2+ und iOS 4.2+ (iPhone, iPad, iPod). Wir verwenden die Google Fonts also, damit wir unser gesamtes Online-Service so schön und einheitlich wie möglich darstellen können.

Welche Daten werden von Google gespeichert?
Wenn Sie unsere Webseite besuchen, werden die Schriften über einen Google-Server nachgeladen. Durch diesen externen Aufruf werden Daten an die Google-Server übermittelt. So erkennt Google auch, dass Sie bzw. Ihre IP-Adresse unsere Webseite besucht. Die Google Fonts API wurde entwickelt, um Verwendung, Speicherung und Erfassung von Endnutzerdaten auf das zu reduzieren, was für eine ordentliche Bereitstellung von Schriften nötig ist. API steht übrigens für „Application Programming Interface“ und dient unter anderem als Datenübermittler im Softwarebereich.

Google Fonts speichert CSS- und Schrift-Anfragen sicher bei Google und ist somit geschützt. Durch die gesammelten Nutzungszahlen kann Google feststellen, wie gut die einzelnen Schriften ankommen. Die Ergebnisse veröffentlicht Google auf internen Analyseseiten, wie beispielsweise Google Analytics. Zudem verwendet Google auch Daten des eigenen Web-Crawlers, um festzustellen, welche Webseiten Google-Schriften verwenden. Diese Daten werden in der BigQuery-Datenbank von Google Fonts veröffentlicht. Unternehmer und Entwickler nützen das Google-Webservice BigQuery, um große Datenmengen untersuchen und bewegen zu können.

Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage auch Informationen wie Spracheinstellungen, IP-Adresse, Version des Browsers, Bildschirmauflösung des Browsers und Name des Browsers automatisch an die Google-Server übertragen werden. Ob diese Daten auch gespeichert werden, ist nicht klar feststellbar bzw. wird von Google nicht eindeutig kommuniziert.

Wie lange und wo werden die Daten gespeichert?
Anfragen für CSS-Assets speichert Google einen Tag lang auf seinen Servern, die hauptsächlich außerhalb der EU angesiedelt sind. Das ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten zu nutzen. Ein Stylesheet ist eine Formatvorlage, über die man einfach und schnell z.B. das Design bzw. die Schriftart einer Webseite ändern kann.

Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt damit das Ziel, die Ladezeit von Webseiten grundsätzlich zu verbessern. Wenn Millionen von Webseiten auf die gleichen Schriften verweisen, werden sie nach dem ersten Besuch zwischengespeichert und erscheinen sofort auf allen anderen später besuchten Webseiten wieder. Manchmal aktualisiert Google Schriftdateien, um die Dateigröße zu reduzieren, die Abdeckung von Sprache zu erhöhen und das Design zu verbessern.

Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können nicht einfach gelöscht werden. Die Daten werden beim Seitenaufruf automatisch an Google übermittelt. Um diese Daten vorzeitig löschen zu können, müssen Sie den Google-Support auf https://support.google.com/?hl=de&tid=121628621 kontaktieren. Datenspeicherung verhindern Sie in diesem Fall nur, wenn Sie unsere Seite nicht besuchen.

Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir können also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie auf https://developers.google.com/fonts/faq?tid=121628621. Dort geht zwar Google auf datenschutzrelevante Angelegenheiten ein, doch wirklich detaillierte Informationen über Datenspeicherung sind nicht enthalten. Es ist relativ schwierig, von Google wirklich präzise Informationen über gespeicherten Daten zu bekommen.

Welche Daten grundsätzlich von Google erfasst werden und wofür diese Daten verwendet werden, können Sie auch auf https://www.google.com/intl/de/policies/privacy/ nachlesen.</h4>


<h2>Google Fonts Lokal Datenschutzerklärung</h2>
<h4>Auf unserer Website nutzen wir Google Fonts der Firma Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) verantwortlich. Wir haben die Google-Schriftarten lokal, d.h. auf unserem Webserver – nicht auf den Servern von Google – eingebunden. Dadurch gibt es keine Verbindung zu Google-Servern und somit auch keine Datenübertragung oder Speicherung.

Was sind Google Fonts?
Früher nannte man Google Fonts auch Google Web Fonts. Dabei handelt es sich um ein interaktives Verzeichnis mit über 800 Schriftarten, die Google kostenlos bereitstellt. Mit Google Fonts könnte man Schriften nutzen, ohne sie auf den eigenen Server hochzuladen. Doch um diesbezüglich jede Informationsübertragung zu Google-Servern zu unterbinden, haben wir die Schriftarten auf unseren Server heruntergeladen. Auf diese Weise handeln wir datenschutzkonform und senden keine Daten an Google Fonts weiter.

Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir können also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie auf https://developers.google.com/fonts/faq?tid=121628621.</h4>
<h2>OpenStreetMap Datenschutzerklärung</h2>
<h4>Wir haben auf unserer Website Kartenausschnitte des Online-Kartentools „OpenStreetMap“ eingebunden. Dabei handelt es sich um ein sogenanntes Open-Source-Mapping, welches wir über eine API (Schnittstelle) abrufen können. Angeboten wird diese Funktion von OpenStreetMap Foundation, St John’s Innovation Centre, Cowley Road, Cambridge, CB4 0WS, United Kingdom. Durch die Verwendung dieser Kartenfunktion wird Ihre IP-Adresse an OpenStreetMap weitergeleitet. In dieser Datenschutzerklärung erfahren Sie warum wir Funktionen des Tools OpenStreetMap verwenden, wo welche Daten gespeichert werden und wie Sie diese Datenspeicherung verhindern können.</h4>
       <h4>Was ist OpenStreetMap?
Das Projekt OpenStreetMap wurde 2004 ins Leben gerufen. Ziel des Projekts ist und war es, eine freie Weltkarte zu erschaffen. User sammeln weltweit Daten etwa über Gebäude, Wälder, Flüsse und Straßen. So entstand über die Jahre eine umfangreiche, von Usern selbst erstellte digitale Weltkarte. Selbstverständlich ist die Karte, nicht vollständig, aber in den meisten Regionen mit sehr vielen Daten ausgestattet.

Warum verwenden wir OpenStreetMap auf unserer Website?
Unsere Website soll Ihnen in erster Linie hilfreich sein. Und das ist sie aus unserer Sicht immer dann, wenn man Information schnell und einfach findet. Da geht es natürlich einerseits um unsere Dienstleistungen und Produkte, andererseits sollen Ihnen auch weitere hilfreiche Informationen zur Verfügung stehen. Deshalb nutzen wir auch den Kartendienst OpenStreetMap. Denn so können wir Ihnen beispielsweise genau zeigen, wie Sie unsere Firma finden. Die Karte zeigt Ihnen den besten Weg zu uns und Ihre Anfahrt wird zum Kinderspiel.

Welche Daten werden von OpenStreetMap gespeichert?
Wenn Sie eine unserer Webseiten besuchen, die OpenStreetMap anbietet, werden Nutzerdaten an den Dienst übermittelt und dort gespeichert. OpenStreetMap sammelt etwa Informationen über Ihre Interaktionen mit der digitalen Karte, Ihre IP-Adresse, Daten zu Ihrem Browser, Gerätetyp, Betriebssystem und an welchem Tag und zu welcher Uhrzeit Sie den Dienst in Anspruch genommen haben. Dafür wird auch Tracking-Software zur Aufzeichnung von Userinteraktionen verwendet. Das Unternehmen gibt hier in der eigenen Datenschutzerklärung das Analysetool „Piwik“ an.

Die erhobenen Daten sind in Folge den entsprechenden Arbeitsgruppen der OpenStreetMap Foundation zugänglich. Laut dem Unternehmen werden persönliche Daten nicht an andere Personen oder Firmen weitergegeben, außer dies ist rechtlich notwendig. Der Drittanbieter Piwik speichert zwar Ihre IP-Adresse, allerdings in gekürzter Form.

Folgendes Cookie kann in Ihrem Browser gesetzt werden, wenn Sie mit OpenStreetMap auf unserer Website interagieren:

Name: _osm_location
Wert: 9.63312%7C52.41500%7C17%7CM
Verwendungszweck: Das Cookie wird benötigt, um die Inhalte von OpenStreetMap zu entsperren.
Ablaufdatum: nach 10 Jahren

Wenn Sie sich das Vollbild der Karte ansehen wollen, werden Sie auf die OpenStreetMap-Website verlinkt. Dort können unter anderem folgende Cookies in Ihrem Browser gespeichert werden:

Name: _osm_totp_token
Wert: 148253121628621-2
Verwendungszweck: Dieses Cookie wird benutzt, um die Bedienung des Kartenausschnitts zu gewährleisten.
Ablaufdatum: nach einer Stunde

Name: _osm_session
Wert: 1d9bfa122e0259d5f6db4cb8ef653a1c
Verwendungszweck: Mit Hilfe des Cookies können Sitzungsinformationen (also Userverhalten) gespeichert werden.
Ablaufdatum: nach Sitzungsende

Name: _pk_id.1.cf09
Wert: 4a5.1593684142.2.1593688396.1593688396121628621-9
Verwendungszweck: Dieses Cookie wird von Piwik gesetzt, um Userdaten wie etwa das Klickverhalten zu speichern bzw. zu messen.
Ablaufdatum: nach einem Jahr

Wie lange und wo werden die Daten gespeichert?
Die API-Server, die Datenbanken und die Server von Hilfsdiensten befinden sich derzeit im Vereinten Königreich (Großbritannien und Nordirland) und in den Niederlanden. Ihre IP-Adresse und Userinformationen, die in gekürzter Form durch das Webanalysetool Piwik gespeichert werden, werden nach 180 Tagen wieder gelöscht.

Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
Sie haben jederzeit das Recht auf Ihre personenbezogenen Daten zuzugreifen und Einspruch gegen die Nutzung und Verarbeitung zu erheben. Cookies, die von OpenStreetMap möglicherweise gesetzt werden, können Sie in Ihrem Browser jederzeit verwalten, löschen oder deaktivieren. Dadurch wird allerdings der Dienst nicht mehr im vollen Ausmaß funktionieren. Bei jedem Browser funktioniert die Verwaltung, Löschung oder Deaktivierung von Cookies etwas anders. Im Folgenden finden Sie Links zu den Anleitungen der bekanntesten Browser:

Chrome: Cookies in Chrome löschen, aktivieren und verwalten

Safari: Verwalten von Cookies und Websitedaten mit Safari

Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben

Internet Explorer: Löschen und Verwalten von Cookies

Microsoft Edge: Löschen und Verwalten von Cookies

Wenn Sie mehr über die Datenverarbeitung durch OpenStreetMap erfahren wollen, empfehlen wir Ihnen die Datenschutzerklärung des Unternehmens unter https://wiki.osmfoundation.org/wiki/Privacy_Policy.</h4>

<h2>Google Analytics Datenschutzerklärung</h2>
<h4>Wir verwenden auf unserer Website das Analyse-Tracking Tool Google Analytics (GA) des amerikanischen Unternehmens Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich. Google Analytics sammelt Daten über Ihre Handlungen auf unserer Website. Wenn Sie beispielsweise einen Link anklicken, wird diese Aktion in einem Cookie gespeichert und an Google Analytics versandt. Mithilfe der Berichte, die wir von Google Analytics erhalten, können wir unsere Website und unser Service besser an Ihre Wünsche anpassen. Im Folgenden gehen wir näher auf das Tracking Tool ein und informieren Sie vor allem darüber, welche Daten gespeichert werden und wie Sie das verhindern können.</h4>
     <h4>Google Analytics ist ein Trackingtool, das der Datenverkehrsanalyse unserer Website dient. Damit Google Analytics funktioniert, wird ein Tracking-Code in den Code unserer Website eingebaut. Wenn Sie unsere Website besuchen, zeichnet dieser Code verschiedene Handlungen auf, die Sie auf unserer Website ausführen. Sobald Sie unsere Website verlassen, werden diese Daten an die Google-Analytics-Server gesendet und dort gespeichert.

Google verarbeitet die Daten und wir bekommen Berichte über Ihr Userverhalten. Dabei kann es sich unter anderem um folgende Berichte handeln:

Zielgruppenberichte: Über Zielgruppenberichte lernen wir unsere User besser kennen und wissen genauer, wer sich für unser Service interessiert.
Anzeigeberichte: Durch Anzeigeberichte können wir unsere Onlinewerbung leichter analysieren und verbessern.
Akquisitionsberichte: Akquisitionsberichte geben uns hilfreiche Informationen darüber, wie wir mehr Menschen für unser Service begeistern können.
Verhaltensberichte: Hier erfahren wir, wie Sie mit unserer Website interagieren. Wir können nachvollziehen welchen Weg Sie auf unserer Seite zurücklegen und welche Links Sie anklicken.
Conversionsberichte: Conversion nennt man einen Vorgang, bei dem Sie aufgrund einer Marketing-Botschaft eine gewünschte Handlung ausführen. Zum Beispiel, wenn Sie von einem reinen Websitebesucher zu einem Käufer oder Newsletter-Abonnent werden. Mithilfe dieser Berichte erfahren wir mehr darüber, wie unsere Marketing-Maßnahmen bei Ihnen ankommen. So wollen wir unsere Conversionrate steigern.
Echtzeitberichte: Hier erfahren wir immer sofort, was gerade auf unserer Website passiert. Zum Beispiel sehen wir wie viele User gerade diesen Text lesen.
Warum verwenden wir Google Analytics auf unserer Webseite?
Unser Ziel mit dieser Website ist klar: Wir wollen Ihnen das bestmögliche Service bieten. Die Statistiken und Daten von Google Analytics helfen uns dieses Ziel zu erreichen.

Die statistisch ausgewerteten Daten zeigen uns ein klares Bild von den Stärken und Schwächen unserer Website. Einerseits können wir unsere Seite so optimieren, dass sie von interessierten Menschen auf Google leichter gefunden wird. Andererseits helfen uns die Daten, Sie als Besucher besser zu verstehen. Wir wissen somit sehr genau, was wir an unserer Website verbessern müssen, um Ihnen das bestmögliche Service zu bieten. Die Daten dienen uns auch, unsere Werbe- und Marketing-Maßnahmen individueller und kostengünstiger durchzuführen. Schließlich macht es nur Sinn, unsere Produkte und Dienstleistungen Menschen zu zeigen, die sich dafür interessieren.

Welche Daten werden von Google Analytics gespeichert?
Google Analytics erstellt mithilfe eines Tracking-Codes eine zufällige, eindeutige ID, die mit Ihrem Browser-Cookie verbunden ist. So erkennt Sie Google Analytics als neuen User. Wenn Sie das nächste Mal unsere Seite besuchen, werden Sie als „wiederkehrender“ User erkannt. Alle gesammelten Daten werden gemeinsam mit dieser User-ID gespeichert. So ist es überhaupt erst möglich pseudonyme Userprofile auszuwerten.

Durch Kennzeichnungen wie Cookies und App-Instanz-IDs werden Ihre Interaktionen auf unserer Website gemessen. Interaktionen sind alle Arten von Handlungen, die Sie auf unserer Website ausführen. Wenn Sie auch andere Google-Systeme (wie z.B. ein Google-Konto) nützen, können über Google Analytics generierte Daten mit Drittanbieter-Cookies verknüpft werden. Google gibt keine Google Analytics-Daten weiter, außer wir als Websitebetreiber genehmigen das. Zu Ausnahmen kann es kommen, wenn es gesetzlich erforderlich ist.

Folgende Cookies werden von Google Analytics verwendet:

Name: _ga
Wert: 2.1326744211.152121628621-5
Verwendungszweck: Standardmäßig verwendet analytics.js das Cookie _ga, um die User-ID zu speichern. Grundsätzlich dient es zur Unterscheidung der Webseitenbesucher.
Ablaufdatum: nach 2 Jahren

Name: _gid
Wert: 2.1687193234.152121628621-1
Verwendungszweck: Das Cookie dient auch zur Unterscheidung der Webseitenbesucher
Ablaufdatum: nach 24 Stunden

Name: _gat_gtag_UA_property-id
Wert: 1
Verwendungszweck: Wird zum Senken der Anforderungsrate verwendet. Wenn Google Analytics über den Google Tag Manager bereitgestellt wird, erhält dieser Cookie den Namen _dc_gtm_ property-id.
Ablaufdatum: nach 1 Minute

Name: AMP_TOKEN
Wert: keine Angaben
Verwendungszweck: Das Cookie hat einen Token, mit dem eine User ID vom AMP-Client-ID-Dienst abgerufen werden kann. Andere mögliche Werte weisen auf eine Abmeldung, eine Anfrage oder einen Fehler hin.
Ablaufdatum: nach 30 Sekunden bis zu einem Jahr

Name: __utma
Wert: 1564498958.1564498958.1564498958.1
Verwendungszweck: Mit diesem Cookie kann man Ihr Verhalten auf der Website verfolgen und die Leistung messen. Das Cookie wird jedes Mal aktualisiert, wenn Informationen an Google Analytics gesendet werden.
Ablaufdatum: nach 2 Jahren

Name: __utmt
Wert: 1
Verwendungszweck: Das Cookie wird wie _gat_gtag_UA_property-id zum Drosseln der Anforderungsrate verwendet.
Ablaufdatum: nach 10 Minuten

Name: __utmb
Wert: 3.10.1564498958
Verwendungszweck: Dieses Cookie wird verwendet, um neue Sitzungen zu bestimmen. Es wird jedes Mal aktualisiert, wenn neue Daten bzw. Infos an Google Analytics gesendet werden.
Ablaufdatum: nach 30 Minuten

Name: __utmc
Wert: 167421564
Verwendungszweck: Dieses Cookie wird verwendet, um neue Sitzungen für wiederkehrende Besucher festzulegen. Dabei handelt es sich um ein Session-Cookie und wird nur solange gespeichert, bis Sie den Browser wieder schließen.
Ablaufdatum: Nach Schließung des Browsers

Name: __utmz
Wert: m|utmccn=(referral)|utmcmd=referral|utmcct=/
Verwendungszweck: Das Cookie wird verwendet, um die Quelle des Besucheraufkommens auf unserer Website zu identifizieren. Das heißt, das Cookie speichert, von wo Sie auf unsere Website gekommen sind. Das kann eine andere Seite bzw. eine Werbeschaltung gewesen sein.
Ablaufdatum: nach 6 Monaten

Name: __utmv
Wert: keine Angabe
Verwendungszweck: Das Cookie wird verwendet, um benutzerdefinierte Userdaten zu speichern. Es wird immer aktualisiert, wenn Informationen an Google Analytics gesendet werden.
Ablaufdatum: nach 2 Jahren

Anmerkung: Diese Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google die Wahl ihrer Cookies immer wieder auch verändert.

Hier zeigen wir Ihnen einen Überblick über die wichtigsten Daten, die mit Google Analytics erhoben werden:

Heatmaps: Google legt sogenannte Heatmaps an. Über Heatmaps sieht man genau jene Bereiche, die Sie anklicken. So bekommen wir Informationen, wo Sie auf unserer Seite „unterwegs“ sind.

Sitzungsdauer: Als Sitzungsdauer bezeichnet Google die Zeit, die Sie auf unserer Seite verbringen, ohne die Seite zu verlassen. Wenn Sie 20 Minuten inaktiv waren, endet die Sitzung automatisch.

Absprungrate (engl. Bouncerate): Von einem Absprung ist die Rede, wenn Sie auf unserer Website nur eine Seite ansehen und dann unsere Website wieder verlassen.

Kontoerstellung: Wenn Sie auf unserer Website ein Konto erstellen bzw. eine Bestellung machen, erhebt Google Analytics diese Daten.

IP-Adresse: Die IP-Adresse wird nur in gekürzter Form dargestellt, damit keine eindeutige Zuordnung möglich ist.

Standort: Über die IP-Adresse kann das Land und Ihr ungefährer Standort bestimmt werden. Diesen Vorgang bezeichnet man auch als IP- Standortbestimmung.

Technische Informationen: Zu den technischen Informationen zählen unter anderem Ihr Browsertyp, Ihr Internetanbieter oder Ihre Bildschirmauflösung.

Herkunftsquelle: Google Analytics beziehungsweise uns interessiert natürlich auch über welche Website oder welche Werbung Sie auf unsere Seite gekommen sind.

Weitere Daten sind Kontaktdaten, etwaige Bewertungen, das Abspielen von Medien (z.B., wenn Sie ein Video über unsere Seite abspielen), das Teilen von Inhalten über Social Media oder das Hinzufügen zu Ihren Favoriten. Die Aufzählung hat keinen Vollständigkeitsanspruch und dient nur zu einer allgemeinen Orientierung der Datenspeicherung durch Google Analytics.

Wie lange und wo werden die Daten gespeichert?
Google hat Ihre Server auf der ganzen Welt verteilt. Die meisten Server befinden sich in Amerika und folglich werden Ihre Daten meist auf amerikanischen Servern gespeichert. Hier können Sie genau nachlesen wo sich die Google-Rechenzentren befinden: https://www.google.com/about/datacenters/inside/locations/?hl=de

Ihre Daten werden auf verschiedenen physischen Datenträgern verteilt. Das hat den Vorteil, dass die Daten schneller abrufbar sind und vor Manipulation besser geschützt sind. In jedem Google-Rechenzentrum gibt es entsprechende Notfallprogramme für Ihre Daten. Wenn beispielsweise die Hardware bei Google ausfällt oder Naturkatastrophen Server lahmlegen, bleibt das Risiko einer Dienstunterbrechung bei Google dennoch gering.

Standardisiert ist bei Google Analytics eine Aufbewahrungsdauer Ihrer Userdaten von 26 Monaten eingestellt. Dann werden Ihre Userdaten gelöscht. Allerdings haben wir die Möglichkeit, die Aufbewahrungsdauer von Nutzdaten selbst zu wählen. Dafür stehen uns fünf Varianten zur Verfügung:

Löschung nach 14 Monaten
Löschung nach 26 Monaten
Löschung nach 38 Monaten
Löschung nach 50 Monaten
Keine automatische Löschung
Wenn der festgelegte Zeitraum abgelaufen ist, werden einmal im Monat die Daten gelöscht. Diese Aufbewahrungsdauer gilt für Ihre Daten, die mit Cookies, Usererkennung und Werbe-IDs (z.B. Cookies der DoubleClick-Domain) verknüpft sind. Berichtergebnisse basieren auf aggregierten Daten und werden unabhängig von Nutzerdaten gespeichert. Aggregierte Daten sind eine Zusammenschmelzung von Einzeldaten zu einer größeren Einheit.

Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
Nach dem Datenschutzrecht der Europäischen Union haben Sie das Recht, Auskunft über Ihre Daten zu erhalten, sie zu aktualisieren, zu löschen oder einzuschränken. Mithilfe des Browser-Add-ons zur Deaktivierung von Google Analytics-JavaScript (ga.js, analytics.js, dc.js) verhindern Sie, dass Google Analytics Ihre Daten verwendet. Das Browser-Add-on können Sie unter https://tools.google.com/dlpage/gaoptout?hl=de runterladen und installieren. Beachten Sie bitte, dass durch dieses Add-on nur die Datenerhebung durch Google Analytics deaktiviert wird.

Falls Sie grundsätzlich Cookies (unabhängig von Google Analytics) deaktivieren, löschen oder verwalten wollen, gibt es für jeden Browser eine eigene Anleitung:

Chrome: Cookies in Chrome löschen, aktivieren und verwalten

Safari: Verwalten von Cookies und Websitedaten mit Safari

Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben

Internet Explorer: Löschen und Verwalten von Cookies

Microsoft Edge: Löschen und Verwalten von Cookies

Google Analytics ist aktiver Teilnehmer beim EU-U.S. Privacy Shield Framework, wodurch der korrekte und sichere Datentransfer persönlicher Daten geregelt wird. Mehr Informationen dazu finden Sie auf https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI&tid=121628621. Wir hoffen wir konnten Ihnen die wichtigsten Informationen rund um die Datenverarbeitung von Google Analytics näherbringen. Wenn Sie mehr über den Tracking-Dienst erfahren wollen, empfehlen wir diese beiden Links: http://www.google.com/analytics/terms/de.html und https://support.google.com/analytics/answer/6004245?hl=de. </h4>  
    
    <h2>Facebook-Pixel Datenschutzerklärung</h2>
    <h4>Wir verwenden auf unserer Webseite das Facebook-Pixel von Facebook. Dafür haben wir einen Code auf unserer Webseite implementiert. Der Facebook-Pixel ist ein Ausschnitt aus JavaScript-Code, der eine Ansammlung von Funktionen lädt, mit denen Facebook Ihre Userhandlungen verfolgen kann, sofern Sie über Facebook-Ads auf unsere Webseite gekommen sind. Wenn Sie beispielsweise ein Produkt auf unserer Webseite erwerben, wird das Facebook-Pixel ausgelöst und speichert Ihre Handlungen auf unserer Webseite in einem oder mehreren Cookies. Diese Cookies ermöglichen es Facebook Ihre Userdaten (Kundendaten wie IP-Adresse, User-ID) mit den Daten Ihres Facebook-Kontos abzugleichen. Dann löscht Facebook diese Daten wieder. Die erhobenen Daten sind für uns anonym und nicht einsehbar und werden nur im Rahmen von Werbeanzeigenschaltungen nutzbar. Wenn Sie selbst Facebook-User sind und angemeldet sind, wird der Besuch unserer Webseite automatisch Ihrem Facebook-Benutzerkonto zugeordnet.

Wir wollen unsere Dienstleistungen bzw. Produkte nur jenen Menschen zeigen, die sich auch wirklich dafür interessieren. Mithilfe von Facebook-Pixel können unsere Werbemaßnahmen besser auf Ihre Wünsche und Interessen abgestimmt werden. So bekommen Facebook-User (sofern sie personalisierte Werbung erlaubt haben) passende Werbung zu sehen. Weiters verwendet Facebook die erhobenen Daten zu Analysezwecken und eigenen Werbeanzeigen.

Im Folgenden zeigen wir Ihnen jene Cookies, die durch das Einbinden von Facebook-Pixel auf einer Testseite gesetzt wurden. Bitte beachten Sie, dass dies nur Beispiel-Cookies sind. Je nach Interaktion auf unserer Webseite werden unterschiedliche Cookies gesetzt.

Name: _fbp
Wert: fb.1.1568287647279.257405483-6121628621-7
Verwendungszweck: Dieses Cookie verwendet Facebook, um Werbeprodukte anzuzeigen.
Ablaufdatum: nach 3 Monaten

Name: fr
Wert: 0aPf312HOS5Pboo2r..Bdeiuf…1.0.Bdeiuf.
Verwendungszweck: Dieses Cookie wird verwendet, damit Facebook-Pixel auch ordentlich funktioniert.
Ablaufdatum: nach 3 Monaten

Name: comment_author_50ae8267e2bdf1253ec1a5769f48e062121628621-3
Wert: Name des Autors
Verwendungszweck: Dieses Cookie speichert den Text und den Namen eines Users, der beispielsweise einen Kommentar hinterlässt.
Ablaufdatum: nach 12 Monaten

Name: comment_author_url_50ae8267e2bdf1253ec1a5769f48e062
Wert: https%3A%2F%2Fwww.testseite…%2F (URL des Autors)
Verwendungszweck: Dieses Cookie speichert die URL der Website, die der User in einem Textfeld auf unserer Webseite eingibt.
Ablaufdatum: nach 12 Monaten

Name: comment_author_email_50ae8267e2bdf1253ec1a5769f48e062
Wert: E-Mail-Adresse des Autors
Verwendungszweck: Dieses Cookie speichert die E-Mail-Adresse des Users, sofern er sie auf der Website bekannt gegeben hat.
Ablaufdatum: nach 12 Monaten

Anmerkung: Die oben genannten Cookies beziehen sich auf ein individuelles Userverhalten. Speziell bei der Verwendung von Cookies sind Veränderungen bei Facebook nie auszuschließen.

Sofern Sie bei Facebook angemeldet sind, können Sie Ihre Einstellungen für Werbeanzeigen unter https://www.facebook.com/ads/preferences/?entry_product=ad_settings_screen selbst verändern. Falls Sie kein Facebook-User sind, können Sie auf http://www.youronlinechoices.com/de/praferenzmanagement/ grundsätzlich Ihre nutzungsbasierte Online-Werbung verwalten. Dort haben Sie die Möglichkeit, Anbieter zu deaktivieren bzw. zu aktivieren.

Wenn Sie mehr über den Datenschutz von Facebook erfahren wollen, empfehlen wir Ihnen die eigenen Datenrichtlinien des Unternehmens auf https://www.facebook.com/policy.php.</h4>
  <h2>Newsletter Datenschutzerklärung</h2>  
  <h4>Wenn Sie sich für unseren Newsletter eintragen übermitteln Sie die oben genannten persönlichen Daten und geben uns das Recht Sie per E-Mail zu kontaktieren. Die im Rahmen der Anmeldung zum Newsletter gespeicherten Daten nutzen wir ausschließlich für unseren Newsletter und geben diese nicht weiter.

Sollten Sie sich vom Newsletter abmelden – Sie finden den Link dafür in jedem Newsletter ganz unten – dann löschen wir alle Daten die mit der Anmeldung zum Newsletter gespeichert wurden.</h4>
     <h2>Google AdSense Datenschutzerklärung
</h2>
<h4>Wir verwenden auf dieser Website Google AdSense. Das ist ein Anzeigenprogramm der Firma Google Inc. In Europa ist das Unternehmen Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Irland) für alle Google-Dienste verantwortlich. Mit Google AdSense können wir auf dieser Webseite Werbeanzeigen einblenden, die zu unserem Thema passen. So bieten wir Ihnen Anzeigen, die im Idealfall einen richtigen Mehrwert für Sie darstellen. Im Zuge dieser Datenschutzerklärung über Google AdSense erklären wir Ihnen, warum wir Google AdSense auf unserer Webseite verwenden, welche Ihrer Daten verarbeitet und gespeichert werden und wie Sie diese Datenspeicherung unterbinden können.</h4>
     <h4>Was ist Google AdSense?</h4>
     
     <h4>Das Werbeprogramm Google AdSense gibt es mittlerweile seit 2003. Im Gegensatz zu Google Ads (früher: Google AdWords) kann man hier nicht selbst Werbung schalten. Über Google AdSense werden Werbeanzeigen auf Webseiten, wie zum Beispiel auf unserer, ausgespielt. Der größte Vorteil dieses Werbedienstes im Vergleich zu manch anderen ist, dass Ihnen Google AdSense nur Anzeigen zeigt, die zu unseren Inhalten passen. Google hat einen eigenen Algorithmus, der berechnet, welche Werbeanzeigen Sie zu Gesicht bekommen. Natürlich wollen wir Ihnen nur Werbung bieten, die Sie auch interessiert und Ihnen einen Mehrwert bietet. Google überprüft anhand Ihrer Interessen bzw. Ihres Userverhaltens und anhand unseres Angebots, welche Werbeanzeigen für unsere Webseite und für unserer User geeignet sind. An dieser Stelle wollen wir auch gleich erwähnen, dass wir für die Auswahl der Werbeanzeigen nicht verantwortlich sind. Wir bieten mit unserer Webseite lediglich die Werbefläche an. Die Auswahl der angezeigten Werbung trifft Google. Seit August 2013 werden die Anzeigen auch an die jeweilige Benutzeroberfläche angepasst. Das heißt, egal ob Sie von Ihrem Smartphone, Ihrem PC oder Laptop unsere Webseite besuchen, die Anzeigen passen sich an Ihr Endgerät an.

Warum verwenden wir Google AdSense auf unserer Webseite?
Das Betreiben einer hochwertigen Webseite erfordert viel Hingabe und großen Einsatz. Im Grunde sind wir mit der Arbeit an unserer Webseite nie fertig. Wir versuchen stets unsere Seite zu pflegen und so aktuell wie möglich zu halten. Natürlich wollen wir mit dieser Arbeit auch einen wirtschaftlichen Erfolg erzielen. Darum haben wir uns für Werbeanzeigen als Einnahmequelle entschieden. Das Wichtigste für uns ist allerdings, Ihren Besuch auf unserer Webseite durch diese Anzeigen nicht zu stören. Mithilfe von Google AdSense wird Ihnen nur Werbung angeboten, die zu unseren Themen und Ihren Interessen passt.

Ähnlich wie bei der Google-Indexierung für eine Webseite, untersucht ein Bot den entsprechenden Content und die entsprechenden Angebote unserer Webseite. Dann werden die Werbeanzeigen inhaltlich angepasst und auf der Webseite präsentiert. Neben den inhaltlichen Überschneidungen zwischen Anzeige und Webseiten-Angebot unterstützt AdSense auch interessensbezogenes Targeting. Das bedeutet, dass Google auch Ihre Daten dazu verwendet, um auf Sie zugeschnittene Werbung anzubieten. So erhalten Sie Werbung, die Ihnen im Idealfall einen echten Mehrwert bietet und wir haben eine höhere Chance ein bisschen etwas zu verdienen.

Welche Daten werden von Google AdSense gespeichert?
Damit Google AdSense eine maßgeschneiderte, auf Sie angepasste Werbung anzeigen kann, werden unter anderem Cookies verwendet. Cookies sind kleine Textdateien, die bestimmte Informationen auf Ihrem Computer speichern.

In AdSense sollen Cookies bessere Werbung ermöglichen. Die Cookies enthalten keine personenidentifizierbaren Daten. Hierbei ist allerdings zu beachten, dass Google Daten wie zum Beispiel “Pseudonyme Cookie-IDs” (Name oder anderes Identifikationsmerkmal wird durch ein Pseudonym ersetzt) oder IP-Adressen als nicht personenidentifizierbare Informationen ansieht. Im Rahmen der DSGVO können diese Daten allerdings als personenbezogene Daten gelten. Google AdSense sendet nach jeder Impression (das ist immer dann der Fall, wenn Sie eine Anzeige sehen), jedem Klick und jeder anderen Aktivität, die zu einem Aufruf der Google AdSense-Server führt, ein Cookie an den Browser. Sofern der Browser das Cookie akzeptiert, wird es dort gespeichert.

Drittanbieter können im Rahmen von AdSense unter Umständen Cookies in Ihrem Browser platzieren und auslesen bzw. Web-Beacons verwenden, um Daten zu speichern, die sie durch die Anzeigenbereitstellung auf der Webseite erhalten. Als Web-Beacons bezeichnet man kleine Grafiken, die eine Logdatei-Analyse und eine Aufzeichnung der Logdatei machen. Diese Analyse ermöglicht eine statistische Auswertung für das Online-Marketing.

Google kann über diese Cookies bestimmte Informationen über Ihr Userverhalten auf unserer Webseite sammeln. Dazu zählen:

Informationen wie Sie mit einer Anzeige umgehen (Klicks, Impression, Mausbewegungen)
Informationen, ob in Ihrem Browser schon eine Anzeige zu einem früheren Zeitpunkt erschienen ist. Diese Daten helfen dabei, Ihnen eine Anzeige nicht öfter anzuzeigen.
Dabei analysiert Google die Daten zu den angezeigten Werbemitteln und Ihre IP-Adresse und wertet diese aus. Google verwendet die Daten in erster Linie, um die Effektivität einer Anzeige zu messen und das Werbeangebot zu verbessern. Diese Daten werden nicht mit personenbezogenen Daten, die Google möglicherweise über andere Google-Dienste von Ihnen hat, verknüpft.

Im Folgenden stellen wir Ihnen Cookies vor, die Google AdSense für Trackingzwecke verwendet. Hierbei beziehen wir uns auf eine Test-Webseite, die ausschließlich Google AdSense installiert hat: 

Name: uid
Wert: 891269189121628621-8
Verwendungszweck: Das Cookie wird unter der Domain adform.net gespeichert. Es stellt eine eindeutig zugewiesene, maschinell generierte User-ID bereit und sammelt Daten über die Aktivität auf unserer Webseite.
Ablaufdatum: nach 2 Monaten

Name: C
Wert: 1
Verwendungszweck: Dieses Cookie identifiziert, ob Ihr Browser Cookies akzeptiert. Das Cookie wird unter der Domain track.adform.net gespeichert.
Ablaufdatum: nach 1 Monat

Name: cid
Wert: 8912691894970695056,0,0,0,0
Verwendungszweck: Dieses Cookie wird unter der Domain track.adform.net gespeichert, steht für Client-ID und wird verwendet, um die Werbung für Sie zu verbessern. Es kann relevantere Werbung an den Besucher weiterleiten und hilft, die Berichte über die Kampagnenleistung zu verbessern.
Ablaufdatum: nach 2 Monaten

Name: IDE
Wert: zOtj4TWxwbFDjaATZ2TzNaQmxrU121628621-1
Verwendungszweck: Das Cookie wird unter der Domain doubleclick.net gespeichert. Es dient dazu, Ihre Aktionen nach der Anzeige bzw. nach dem Klicken der Anzeige zu registrieren. Dadurch kann man messen, wie gut eine Anzeige bei unseren Besuchern ankommt.
Ablaufdatum: nach 1 Monat

Name: test_cookie
Wert: keine Angabe
Verwendungszweck: Mithilfe des „test_cookies“ kann man überprüfen, ob Ihr Browser überhaupt Cookies unterstützt. Das Cookie wird unter der Domain doubleclick.net gespeichert.
Ablaufdatum: nach 1 Monat

Name: CT592996
Wert:733366
Verwendungszweck: Wird unter der Domain adform.net gespeichert. Das Cookie wird gesetzt sobald Sie auf eine Werbeanzeige klicken. Genauere Informationen über die Verwendung dieses Cookies konnten wir nicht in Erfahrung bringen.
Ablaufdatum: nach einer Stunde

Anmerkung: Diese Aufzählung kann keinen Anspruch auf Vollständigkeit erheben, da Google erfahrungsgemäß die Wahl seiner Cookies immer wieder verändert.

Wie lange und wo werden die Daten gespeichert?
Google erfasst Ihre IP-Adresse und verschiedene Aktivitäten, die Sie auf der Webseite ausführen. Cookies speichern diese Informationen zu den Interaktionen auf unserer Webseite. Laut Google sammelt und speichert das Unternehmen die angegebenen Informationen auf sichere Weise auf den hauseigenen Google-Servern in den USA.

Wenn Sie kein Google-Konto haben bzw. nicht angemeldet sind, speichert Google die erhobenen Daten mit einer eindeutigen Kennung (ID) meist auf Ihrem Browser. Die in Cookies gespeicherten eindeutigen IDs dienen beispielsweise dazu, personalisierte Werbung zu gewährleisten. Wenn Sie in einem Google-Konto angemeldet sind, kann Google auch personenbezogene Daten erheben.

Einige der Daten, die Google speichert, können Sie jederzeit wieder löschen (siehe nächsten Abschnitt). Viele Informationen, die in Cookies gespeichert sind, werden automatisch nach einer bestimmten Zeit wieder gelöscht. Es gibt allerdings auch Daten, die von Google über einen längeren Zeitraum gespeichert werden. Dies ist dann der Fall, wenn Google aus wirtschaftlichen oder rechtlichen Notwendigkeiten, gewisse Daten über einen unbestimmten, längeren Zeitraum speichern muss.

Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?
Sie haben immer die Möglichkeit Cookies, die sich auf Ihrem Computer befinden, zu löschen oder zu deaktivieren. Wie genau das funktioniert hängt von Ihrem Browser ab.

Hier finden Sie die Anleitung, wie Sie Cookies in Ihrem Browser verwalten:

Chrome: Cookies in Chrome löschen, aktivieren und verwalten

Safari: Verwalten von Cookies und Websitedaten mit Safari

Firefox: Cookies löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben

Internet Explorer: Löschen und Verwalten von Cookies

Microsoft Edge: Löschen und Verwalten von Cookies

Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden, ob Sie das Cookie erlauben oder nicht. Durch das Herunterladen und Installieren dieses Browser-Plug-ins auf https://support.google.com/ads/answer/7395996 werden ebenfalls alle „Werbecookies“ deaktiviert. Bedenken Sie, dass Sie durch das Deaktivieren dieser Cookies nicht die Werbeanzeigen verhindern, sondern nur die personalisierte Werbung.

Wenn Sie ein Google Konto besitzen, können Sie auf der Webseite https://adssettings.google.com/authenticated personalisierte Werbung deaktivieren. Auch hier sehen Sie dann weiter Anzeigen, allerdings sind diese nicht mehr an Ihre Interessen angepasst. Dennoch werden die Anzeigen auf der Grundlage von ein paar Faktoren, wie Ihrem Standort, dem Browsertyp und der verwendeten Suchbegriffe, angezeigt.

Welche Daten Google grundsätzlich erfasst und wofür sie diese Daten verwenden, können Sie auf https://www.google.com/intl/de/policies/privacy/ nachlesen.</h4>
     <h2>Eingebettete Social Media Elemente Datenschutzerklärung</h2>
     <h4>Wir binden auf unserer Webseite Elemente von Social Media Diensten ein um Bilder, Videos und Texte anzuzeigen.
Durch den Besuch von Seiten die diese Elemente darstellen, werden Daten von Ihrem Browser zum jeweiligen Social Media Dienst übertragen und dort gespeichert. Wir haben keinen Zugriff auf diese Daten.
Die folgenden Links führen Sie zu den Seiten der jeweiligen Social Media Dienste wo erklärt wird, wie diese mit Ihren Daten umgehen:

Instagram-Datenschutzrichtlinie: https://help.instagram.com/519522125107875
Für YouTube gilt die Google Datenschutzerklärung: https://policies.google.com/privacy?hl=de
Facebook-Datenrichtline: https://www.facebook.com/about/privacy
Twitter Datenschutzrichtlinie: https://twitter.com/de/privacy</h4>
<h2>Facebook Datenschutzerklärung</h2>
<h4>Wir verwenden auf unserer Webseite ausgewählte Tools von Facebook. Facebook ist ein Social Media Network des Unternehmens Facebook Ireland Ltd., 4 Grand Canal Square, Grand Canal Harbour, Dublin 2 Ireland. Mithilfe dieser Tools können wir Ihnen und Menschen, die sich für unsere Produkte und Dienstleistungen interessieren, das bestmögliche Angebot bieten. Im Folgenden geben wir einen Überblick über die verschiedenen Facebook Tools, welche Daten an Facebook gesendet werden und wie Sie diese Daten löschen können.</h4>
       <h4>Was sind Facebook-Tools?</h4>
       <h4>Neben vielen anderen Produkten bietet Facebook auch die sogenannten “Facebook Business Tools” an. Das ist die offizielle Bezeichnung von Facebook. Da der Begriff aber kaum bekannt ist, haben wir uns dafür entschieden, sie lediglich Facebook-Tools zu nennen. Darunter finden sich unter anderem:

Facebook-Pixel
soziale Plug-ins (wie z.B der „Gefällt mir“- oder „Teilen“-Button)
Facebook Login
Account Kit
APIs (Programmierschnittstelle)
SDKs (Sammlung von Programmierwerkzeugen)
Plattform-Integrationen
Plugins
Codes
Spezifikationen
Dokumentationen
Technologien und Dienstleistungen
Durch diese Tools erweitert Facebook Dienstleistungen und hat die Möglichkeit, Informationen über User-Aktivitäten außerhalb von Facebook zu erhalten.

Warum verwenden wir Facebook-Tools auf unserer Webseite?
Wir wollen unsere Dienstleistungen und Produkte nur Menschen zeigen, die sich auch wirklich dafür interessieren. Mithilfe von Werbeanzeigen (Facebook-Ads) können wir genau diese Menschen erreichen. Damit den Usern passende Werbung gezeigt werden kann, benötigt Facebook allerdings Informationen über die Wünsche und Bedürfnisse der Menschen. So werden dem Unternehmen Informationen über das Userverhalten (und Kontaktdaten) auf unserer Webseite zur Verfügung gestellt. Dadurch sammelt Facebook bessere User-Daten und kann interessierten Menschen die passende Werbung über unsere Produkte bzw. Dienstleistungen anzeigen. Die Tools ermöglichen somit maßgeschneiderte Werbekampagnen auf Facebook.

Daten über Ihr Verhalten auf unserer Webseite nennt Facebook „Event-Daten“. Diese werden auch für Messungs- und Analysedienste verwendet. Facebook kann so in unserem Auftrag „Kampagnenberichte“ über die Wirkung unserer Werbekampagnen erstellen. Weiters bekommen wir durch Analysen einen besseren Einblick, wie Sie unsere Dienstleistungen, Webseite oder Produkte verwenden. Dadurch optimieren wir mit einigen dieser Tools Ihre Nutzererfahrung auf unserer Webseite. Beispielsweise können Sie mit den sozialen Plug-ins Inhalte auf unserer Seite direkt auf Facebook teilen.

Welche Daten werden von Facebook-Tools gespeichert?
Durch die Nutzung einzelner Facebook-Tools können personenbezogene Daten (Kundendaten) an Facebook gesendet werden. Abhängig von den benutzten Tools können Kundendaten wie Name, Adresse, Telefonnummer und IP-Adresse versandt werden.

Facebook verwendet diese Informationen, um die Daten mit den Daten, die es selbst von Ihnen hat (sofern Sie Facebook-Mitglied sind) abzugleichen. Bevor Kundendaten an Facebook übermittelt werden, erfolgt ein sogenanntes „Hashing“. Das bedeutet, dass ein beliebig großer Datensatz in eine Zeichenkette trans</h4>
       
    </div> );
}
 
export default DataProtection;