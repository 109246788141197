import "./App.css";
import React, { useEffect } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import NavBar from "./components/NavBar/NavBar";
import Home from "./pages/Home";
import Shop from "./pages/Shop/Shop";
import SearchPage from "./pages/Search";
import About from "./pages/About";
import Team from "./pages/Team";
import ShippingPage from "./pages/Shipping";
import Imprint from "./pages/Imprint";
import DataProtection from "./pages/DataProtection";
import Contact from "./pages/Contact";
import Footer from "./components/Footer/Footer";
import { SearchProvider } from "./contexts/SearchContextProvider";
import ContributePage from "./pages/Contribute";
// import ScrollToTop from "./components/ToTop/ScrollToTop";
import NotFound from "./pages/NotFound";
import ReactGA from "react-ga";
function App() {
  useEffect(() => {
    ReactGA.initialize("UA-158794801-2");
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  return (
    <>
      <SearchProvider>
        <NavBar />
        <div className="main__container">
          <Switch>
            <Route path="/shop/:id" component={Shop} />
            <Route path="/contribute" component={ContributePage} />
            <Route path="/shops/search" component={SearchPage} />
            <Route path="/imprint" component={Imprint} />
            <Route path="/kontakt" component={Contact} />
            <Route path="/Datenschutz" component={DataProtection} />
            <Route path="/versand" component={ShippingPage} />
            <Route path="/team" component={Team} />
            <Route path="/about" component={About} />
            <Route path="/home" component={Home} />
            <Route path="/not-found" component={NotFound} />
            <Redirect from="/" exact to="/home" />
            <Redirect to="/not-found" />
          </Switch>
        </div>
        <Footer />
      </SearchProvider>
    </>
  );
}

export default App;
