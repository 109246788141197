import React from 'react';
import {categories} from "../../data/category";
import { useSearch } from "../../contexts/SearchContextProvider";
import "./main.css"
const FilterComponent = () => {
    const {getter, setter} = useSearch();
    const handleSort=(e)=>{
        setter.sortBy(e.target.value.toLowerCase());
    }
    const handleFilter=(e)=>{
      
        setter.category(e.target.value.toLowerCase());
      
    }
    const handleMaxDistance=(e)=>{
        setter.maxDistance(e.target.value * 1000);
    }
    return ( 
        <div className="filter__container">
            <h4>Filter</h4>
            <div className="category__section">
                <h4 style={{marginBottom:"3vh"}}>
Kategorien :</h4>
                {
                   categories.map((item)=>(
                        <div className="category" key={item.id}>
                    <h4>{item.category}</h4>
                    <input type="radio" style={{cursor:'pointer'}}  value={item.category}  onClick={handleFilter} name="category" />
                </div>
                    ))
                }
            </div>
            <div className="category__section">
<h4>Entfernung :</h4>
<div className="range__container">
    <h4>Max Entfernung : {getter.maxDistance?parseInt(getter.maxDistance/1000):20}km</h4>
    <input className="range__component" type="range" value={getter.maxDistance?parseInt(getter.maxDistance/1000):20} onChange={handleMaxDistance} name="Distance"/>
</div>
            </div>
            <div className="category__section">
                <div className="sort">
                <h4>Sortiert nach:</h4>
                <select className="sort__select" onClick={handleSort} name="Sort By">
                    <option className="sort__options"  value="Relevance">Relevance</option>
                    <option className="sort__options"  value="Distance">Distance</option>
                </select>
                </div>
            </div>
        </div>
     );
}
 
export default FilterComponent;