import { coordToAddress } from "./geocode";

export const setCurrentLocation = (setter) => {
    navigator.geolocation.getCurrentPosition(
        async (position)=>{
            const address = await coordToAddress(position.coords)
           setter.coordinates({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
                address: address
            })
        }, 
        // error
        ()=>null);
}