import { createContext, useState, useEffect, useContext } from "react";
import { useQueryAsState } from "use-route-as-state";
import { rootUrl } from "../config";
import getTags from "../utils/getTags";
import axios from "axios";
const SearchContext = createContext();
export function useSearch() {
  return useContext(SearchContext);
}

export function SearchProvider({ children }) {
  const [shops, setShops] = useState([]);
  const [
    { search, maxDistance, sortBy, pageNo, category, isOpenNow },
    updateQueryParams,
  ] = useQueryAsState({ pageNo: 1 });
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState();
  const [count, setCount] = useState();
  const [coordinates, setCoordinates] = useState({
    latitude: undefined,
    longitude: undefined,
    address: undefined,
  });

  const getter = {
    search,
    maxDistance,
    sortBy,
    pageNo,
    category,
    coordinates,
    isOpenNow,
    count,
    isLoading,
  };

  const setter = {
    search: (search) => {
      updateQueryParams({ search });
    },
    maxDistance: (maxDistance) => {
      updateQueryParams({ maxDistance });
    },
    sortBy: (sortBy) => {
      updateQueryParams({ sortBy });
    },
    pageNo: (pageNo) => {
      updateQueryParams({ pageNo });
    },
    category: (category) => {
      category = category.toLowerCase();
      updateQueryParams({ category });
    },
    coordinates: ({ latitude, longitude, address }) => {
      setCoordinates({ longitude, latitude, address });
    },
    isOpenNow: (isOpenNow) => {
      updateQueryParams({ isOpenNow });
    },
  };

  let cancelToken;
  const handleSearch = async () => {
    if (coordinates && coordinates.latitude && coordinates.longitude) {
      setIsLoading(true);
      setShops([]);

      //Check if there are any previous pending requests
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }

      //Save the cancel token for the current request
      cancelToken = axios.CancelToken.source();

      let currDate;
      if (isOpenNow) {
        currDate = new Date().toISOString();
      }
      let tags;
      if (search && search.trim().length) {
        tags = getTags(search);
      }
      const body = {
        tags,
        coordinates: [coordinates.longitude, coordinates.latitude],
        maxDistance,
        sortBy,
        pageNo,
        category: category === "alle kategorien" ? undefined : category,
        currDate,
      };

      console.log(body);
      try {
        const results = await axios.post(
          `${rootUrl}/shop/search`,
          body,
          { cancelToken: cancelToken.token } //Pass the cancel token to the current request
        );
        setShops(results.data.results);
        setIsLoading(false);
      } catch (error) {
        if (!axios.isCancel(error)) {
          setIsLoading(false);
          setErr(error.message);
          console.log("ERROR!!");
        }
        console.log(error);
      }
    }
  };
  let countCancelToken;
  const handleCount = async () => {
    if (coordinates && coordinates.latitude && coordinates.longitude) {
      //Check if there are any previous pending requests
      if (typeof countCancelToken != typeof undefined) {
        countCancelToken.cancel("Operation canceled due to new request.");
      }

      //Save the cancel token for the current request
      countCancelToken = axios.CancelToken.source();

      let currDate;
      if (isOpenNow) {
        currDate = new Date().toISOString();
      }
      let tags;
      if (search && search.trim().length) {
        tags = getTags(search);
      }
      const body = {
        tags,
        coordinates: [coordinates.longitude, coordinates.latitude],
        maxDistance,
        sortBy,
        pageNo,
        category: category == "all" ? undefined : category,
        currDate,
      };

      console.log(body);
      try {
        const results = await axios.post(
          `${rootUrl}/shop/count`,
          body,
          { cancelToken: countCancelToken.token } //Pass the cancel token to the current request
        );
        setCount(results.data.totalCount);
      } catch (error) {
        if (!axios.isCancel(error)) {
          setErr(error.message);
          console.log("ERROR!!");
        }
        console.log(error);
      }
    }
  };

  useEffect(() => {
    handleCount();
  }, [search, maxDistance, sortBy, category, coordinates]);

  useEffect(() => {
    handleSearch();
  }, [search, maxDistance, sortBy, pageNo, category, coordinates]);

  useEffect(() => {
    if (
      coordinates &&
      coordinates.latitude &&
      coordinates.longitude &&
      coordinates.address
    ) {
      localStorage.setItem("regioheld-location", JSON.stringify(coordinates));
    }
  }, [coordinates]);

  useEffect(() => {
    if (
      !(
        coordinates &&
        coordinates.latitude &&
        coordinates.longitude &&
        coordinates.address
      )
    ) {
      let storedCoords = JSON.parse(localStorage.getItem("regioheld-location"));
      if (
        storedCoords &&
        storedCoords.latitude &&
        storedCoords.longitude &&
        storedCoords.address
      ) {
        setCoordinates(storedCoords);
      }
    }
  }, []);

  const value = { err, isLoading, getter, setter, shops };
  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  );
}
