import React, { useState } from "react";
import { useForm } from "react-hook-form";
import emailjs from 'emailjs-com';
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import "./main.css";
import { useLoadScript } from "@react-google-maps/api";
import getTags from "../../utils/getTags";
import SearchLocation from "../LocationSearchBarForm";
import { useHistory } from "react-router-dom";

const libraries = ["places"];
export default function App() {
  const [phone, setPhone] = useState();
  const { register, handleSubmit, getValues, errors } = useForm();
  const [coords, setCoords] = useState({
    longitude: undefined,
    latitude: undefined,
    address: undefined,
  });
  const [fetchError, setFetchError] = useState(false);
  const [isFetchLoading, setisFetchLoading] = useState(false);
  const history = useHistory(); 
  const onSubmit = (data) => {
    const tags = getTags(`${data.name} ${data.about} ${data.address}${data.category}`);
    // Weeks Kavii ignore maar abhi k liye baad me onclick kr dunga
    let mon = false,
      tue = false,
      wed = false,
      thur = false,
      fri = false,
      sat = false,
      sun = false,
      wifi = false,
      pay = false,
      park = false,
      shipping = false;
    if (data.weeks.includes("mo")) mon = true;
    if (data.weeks.includes("di")) tue = true;
    if (data.weeks.includes("mi")) wed = true;
    if (data.weeks.includes("do")) thur = true;
    if (data.weeks.includes("fr")) fri = true;
    if (data.weeks.includes("sa")) sat = true;
    if (data.weeks.includes("so")) sun = true;
    if (data.services.includes("Wlan")) wifi = true;
    if (data.services.includes("Versand möglich")) shipping = true;
    if (data.services.includes("Kartenzahlung möglich")) pay = true;
    if (data.services.includes("Kundenparkplätze vorhanden")) park = true;
    const start_time =
      parseFloat(data.opening.substring(0, 2)) +
      0.01 * parseFloat(data.opening.substring(3, 5));
    const end_time =
      parseFloat(data.closing.substring(0, 2)) +
      0.01 * parseFloat(data.closing.substring(3, 5));
    const final_data = {
      category :data.category,
      name: data.name,
      location: {
        coordinates: [coords.longitude, coords.latitude],
      },
      address: data.address,
      about: data.about,
      contacts: {
        email: data.email,
        phone: `${phone}`,
      },
      timing: {
        start: start_time,
        end: end_time,
      },
      days: {
        monday: mon,
        tuesday: tue,
        wednesday: wed,
        thursday: thur,
        friday: fri,
        saturday: sat,
        sunday: sun,
      },
      facilities: {
        free_wife: wifi,
        online_payments: pay,
        shipping: shipping,
        free_parking: park,
      },
      // place: data.place,
      tags: tags,
      website:data.website
    };
    //  Yet Push krna h
    console.log("data",final_data);
    setFetchError();
    setisFetchLoading(true);
     const final_mail= JSON.stringify(final_data)
        const templateParams = {
          subject: "Shop contribution/registration request",
          finalData: `${final_mail}`
      };
       
      emailjs.send(process.env.REACT_APP_SERVICE_ID,process.env.REACT_APP_TEMPLATE_ID,templateParams,process.env.REACT_APP_USER_ID)
          .then(function(response) {
             console.log('SUCCESS!', response.status, response.text);
             history.push("/")
          }, function(error) {
             console.log('FAILED...', error);
          });
  };
  console.log(errors);

  const atLeastOne = () =>
    getValues("weeks").length ? true : "Please Select At least One value";

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
    libraries,
  });
  if (loadError) return "Error loading maps";
  if (!isLoaded) return "Loading Maps";
  return (
    <form className="form__component" onSubmit={handleSubmit(onSubmit)}>
      
      <div className="form__namenumber">
        <input
          className="form__input"
          type="text"
          placeholder="Shop Name"
          name="name"
          ref={register({ required: true, maxLength: 80 })}
        />
        <PhoneInput
          className="form__input"
          placeholder="Enter phone number"
          value={phone}
          onChange={setPhone}
        />
      </div>
      <div className="form__namenumber">
      <select className="form__input" name="category" ref={register({ required: true })}>
      <option value="unternehmensberatung">Unternehmensberatung</option>
        <option value="lebensmittel">Lebensmittel</option>
        <option value="hygiene produkte">Hygiene Produkte</option>
        <option value="gutes vom bauern">Gutes vom Bauern</option>
        <option value="schuhe">Schuhe</option>
        <option value="haustierbedarf">Haustierbedarf</option>
        <option value="bekleidung">Bekleidung</option>
        <option value="spielzeug">Spielzeug</option>
        <option value="schmuck">Schmuck</option>
        <option value="getränke">Getränke</option>
        <option value="elektronik">Elektronik</option>
        <option value="heimzubehör">Heimzubehör</option>
        <option value="freizeitartikel">Freizeitartikel</option>
        <option value="möbel">Möbel</option>
        <option value="startups">Startups</option>
        <option value="fotografie">Fotografie</option>
        <option value="fitness">Fitness</option>
        <option value="handwerker">Handwerker</option>
        <option value="kunst & kultur ">Kunst & Kultur </option>
        <option value="gesundheit">Gesundheit</option>
        <option value="baumärkte">Baumärkte</option>
        <option value="alles für´s auto">Alles für´s Auto</option>
        <option value="gastronomie">Gastronomie</option>
        <option value="banken">Banken</option>
        <option value="zahnärzte">Zahnärzte</option>


      </select>
        <input
          type="text"
          className="form__input"
          placeholder="Email"
          name="email"
          ref={register({ pattern: /^\S+@\S+$/i })}
        />
      </div>
      <div className="form__namenumber">
        <input
          type="text"
          className="form__input"
          placeholder="Adresse"
          name="address"
          ref={register({ required: true })}
        />
        <SearchLocation  className="form__input"  coords={coords} setCoords={setCoords} />
      </div>
      <div className="form__namenumber">
        <div className="form__weekcontainer">
          <h4 className="shop__opens">
Shop öffnet am :</h4>
          <div className="form__weekscontainer">
            {["mo", "di", "mi", "do", "fr", "sa", "so"].map((value) => (
              <div className="form__weeks" key={value}>
                <h4>{value}</h4>
                <input
                  className="checkbox__week"
                  type="checkbox"
                  name="weeks"
                  value={value}
                  ref={register({
                    validate: atLeastOne,
                  })}
                />
              </div>
            ))}
          </div>
        </div>
        <div className="form__timecontainer">
          <h4 className="shop__opens">Zeitpunkt des Geschäfts :</h4>
          <div className="form__weekscontainer">
            <div className="form__opentime">
              <h4>
Öffnungszeit</h4>
              <input
                type="time"
                placeholder="Opening Time"
                name="opening"
                ref={register()}
              />
            </div>
            <div className="form__closingtime">
              <h4>
Geschäftsschluss</h4>
              <input
                type="time"
                placeholder="Closing Time"
                name="closing"
                ref={register()}
              />
            </div>
          </div>
        </div>
      </div>
      <h4 style={{ marginBottom: "2vh" }}>
Über Ihren Shop :</h4>
      <textarea
        className="form__aboutshop"
        type="text"
        placeholder="
        Über Ihren Shop"
        name="about"
        ref={register({ required: true })}
      />
       <h4 style={{ marginBottom: "2vh" }}>
Website Link:</h4>
      <input
        className="form__aboutshop form__websitelink"
        type="text"
        placeholder="Webiste Link"
        name="website"
        ref={register()}
      />
      <h4 style={{ marginBottom: "2vh" }}>
      Services für unsere Kunden :
      </h4>
      <div className="form__servicecontainer">
        {[
          "Wlan",
          "Versand möglich",
          "Kartenzahlung möglich",
          "Kundenparkplätze vorhanden",
        ].map((value) => (
          <div className="form__services" key={value}>
            <h4>{value}</h4>
            <input
              className="week__checkbox"
              type="checkbox"
              name="services"
              value={value}
              ref={register()}
            />
          </div>
        ))}
      </div>
      <input className="submit__btn" type="submit"  disabled={isFetchLoading} />
     {
       {fetchError}&&<span className="form__error">{fetchError}</span>
     } 
    </form>
  );
}
