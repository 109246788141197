import React from 'react';
import FilterComponent from '../../components/Filter_SortBy';
import Paginate from '../../components/Paginate';
import "./main.css";
import Loading from "../../components/Loading/Loading"
import SearchShop from './Search__shop';
import { useSearch } from "../../contexts/SearchContextProvider";
const SearchPage = () => {
  const { shops,getter } = useSearch();
    return ( <>
    
        <div className="search__super__container">
      <div className="search__spacer"></div>
  <div className="search__container">   
               <div className="search__aside">
       <FilterComponent/>
    </div>
    {
getter.isLoading?<Loading/>:
              <div className="search__components">
                <h2>
                Ergebnisse für Shops</h2>
                
                {shops && shops.map(shop => {
                  return <SearchShop key={shop._id} shop={shop}/>
                })}

                <div
                className="home__line"
                style={{
                  width: "90%",
                  height: "1px",
                  background: "#AAAAAA",
                  marginTop: "5vh",
                  marginBottom: "5vh",
                }}
              ></div>
                     </div> } 
         

    </div> 

    <Paginate /> 
        </div>
      </> );
}
 
export default SearchPage;